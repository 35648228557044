.record-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0 0px;
  position: relative;

  img {
    width: 100px;
  }

  h5 {
    margin-top: 15px;
    font-size: var(--commonFont-large);
  }
}