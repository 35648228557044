.payment-term-container {
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    overflow: hidden;
    margin-bottom: 12px;

    .mismatch-indicator {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 8px 16px;
        background: #fef2f2;
        border-bottom: 1px solid #fecaca;

        i {
            font-size: 16px;
            color: #dc2626;
        }

        span {
            font-size: 12px;
            color: #dc2626;
            font-weight: 500;
        }
    }

    .comparison-header {
        display: flex;
        background: #f8fafc;
        border-bottom: 1px solid #e2e8f0;
        padding: 8px 16px;

        .header-item {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 13px;
            color: #64748b;
            font-weight: 600;

            i {
                font-size: 16px;
                color: #3b82f6;
            }
        }
    }

    .comparison-content {
        display: flex;
        padding: 12px 16px;
        gap: 16px;

        .term-value {
            flex: 1;
            font-size: 13px;
            color: #1e293b;
            font-weight: 500;
            padding: 8px 12px;
            background: #f8fafc;
            border-radius: 4px;
            border: 1px solid #e2e8f0;

            &.mismatch {
                background: #fef2f2;
                border-color: #fecaca;
            }
        }
    }
}

// Responsive styles
@media screen and (max-width: 768px) {
    .payment-term-container {
        .mismatch-indicator {
            padding: 6px 12px;

            i {
                font-size: 14px;
            }

            span {
                font-size: 11px;
            }
        }

        .comparison-header {
            padding: 6px 12px;

            .header-item {
                font-size: 12px;

                i {
                    font-size: 14px;
                }
            }
        }

        .comparison-content {
            flex-direction: column;
            padding: 8px 12px;
            gap: 8px;

            .term-value {
                font-size: 13px;
                padding: 6px 10px;
            }
        }
    }
}