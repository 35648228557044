.center-model-popup {
  overflow: hidden;

  .modal-dialog {
    top: 46%;
    transform: translateY(-50%) !important;
    -webkit-transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    -o-transform: translateY(-50%) !important;
    transition: transform .3s ease-out;
    -webkit-transition: transform .3s ease-out;
    -moz-transition: transform .3s ease-out;
    -ms-transition: transform .3s ease-out;
    -o-transition: transform .3s ease-out;

    .modal-content {

      .modal-header {
        border-bottom: 1px solid rgba(206, 211, 216, 0.431372549);
        position: relative;
        background: var(--lightBg-Color);
        padding: 0;

        .po-model-title {
          padding: 6px 10px !important;
        }

        .model-title {
          font-size: var(--commonFont-large);
          font-weight: 600;
          padding: 10px;
        }

        .btn-close {
          cursor: pointer;
          --bs-btn-close-bg: none;
          background: url(../../../assets/images/close-icon.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          opacity: 1;
          box-shadow: none !important;
          outline: none !important;
          margin-right: 10px;
          height: 0.5em !important;
        }
      }
    }
  }

  // .modal-dialog {
  //   top: 50% !important;
  //   transform: translateY(-54%) !important;
  // }

  &.width-98 {
    .modal-dialog {
      max-width: 98%;
    }
  }

  &.width-95 {
    .modal-dialog {
      max-width: 95%;
    }
  }

  &.width-70 {
    .modal-dialog {
      max-width: 70%;
    }
  }

  &.w-40 {
    .modal-dialog {
      max-width: 40%;
    }
  }

  &.w-45 {
    .modal-dialog {
      max-width: 45%;
    }
  }

  &.w-50s {
    .modal-dialog {
      max-width: 50%;

      @media only screen and (max-width: 1300px) {
        max-width: 65%;
      }
    }
  }

  &.w-55 {
    .modal-dialog {
      max-width: 55%;
    }
  }

  &.w-60 {
    .modal-dialog {
      max-width: 60%;
    }
  }

  &.w-65 {
    .modal-dialog {
      max-width: 65%;
    }
  }

  &.w-70 {
    .modal-dialog {
      max-width: 70%;
    }
  }

  &.w-75s {
    .modal-dialog {
      max-width: 75%;
    }
  }

  &.w-80 {
    .modal-dialog {
      max-width: 80%;
    }
  }

  &.w-90 {
    .modal-dialog {
      max-width: 90%;
    }
  }

}

