.basic-left-part {
  .card-section-left {
    .card-body-sec {
      .basic-info-sc {
        min-height: calc(100vh - 208px);

        .text-area {
          .text-area-input {
            min-height: 100px;
          }
        }
      }
    }
  }
}

.modal-height{
  @media only screen and (max-width: 1750px) {
    .input-label-part{
      .image-upload-container{
        min-height: 200px;
        .upload-box{
          padding: 10px 0 11px;
        }
      }
    }
  }
}

.customer-desc-left-sec {
  .card-body-sec {
    margin-top: 0;

    .basic-customer-detail {
      .profile-info {
        position: relative;
        border-bottom: 1px solid var(--light-menuDot);
        padding-bottom: 15px;
        margin-bottom: 15px;
        align-items: flex-start;
        .profile-icon-desc {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 0px;

          .profile-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            // background: var(--gradient-theme);
            border-radius: 4px;
            color: var(--whiteColor);
            font-size: var(--commonFont-large);
            box-shadow: var(--card-shadow);
            margin-top: 2px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .profile-icon-avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            background: var(--gradient-theme);
            border-radius: 100%;
            color: var(--whiteColor);
            font-size: var(--commonFont-large);
            box-shadow: var(--card-shadow);
          }
        }

        h5 {
          font-size: var(--commonFont-large);
          font-weight: 600;
          color: var(--themeColor);
          margin-bottom: 0px;
          margin-top: 2px;
          padding-left: 9px;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: all 0.5s ease;
          white-space: nowrap;
          max-width: 335px;
          @media only screen and (max-width: 1750px) {
            max-width: 285px;
          }
          @media only screen and (max-width: 1650px) {
            max-width: 245px;
          }
          @media only screen and (max-width: 1280px) {
            max-width: 195px;
          }
        }

        .email-web-info {
          padding-left: 5px;
          margin-top: 10px;

          .input-label-part {
            margin-bottom: 0px;
            padding-bottom: 0;
            margin-top: 5px;

            span {
              display: flex;
              align-items: center;
              margin-left: 2px;

              img {
                width: 18px;
                margin-right: 10px;
              }

              span {
                img {
                  margin-left: 5px;
                }
              }

              &.email-desc-part {
                margin-bottom: 5px;
                margin-top: 7px;
              }
            }
          }
        }

        .edit-icons {
          cursor: pointer;

          img {
            width: 20px;
          }
        }
      }

      .field-desc {
        display: flex;
        align-items: flex-start;
        margin-bottom: 3px !important;
        margin-top: 5px;
        &.supplier-basic-dropdown {
          padding-right: 15px;
        }
        input[type="checkbox"]:before {
          display: none;
        }

        .checkbox-part .checkbox input {
          top: 32px;
        }

        .checkbox-part .checkbox label {
          &::before {
            width: 18px !important;
            height: 18px !important;
            margin-top: -1px;
            margin-left: -1px;
          }
        }

        .checkbox-label {
          width: 15px !important;
          height: 15px !important;
          margin-top: -7px;
          margin-left: -1px;
        }

        .submission-tab {
          color: var(--themeColor) !important;
          position: relative;
          font-weight: 700 !important;

          i {
            font-size: var(--commonFont);
            margin-left: 5px;
          }
        }
        .eye-icon {
          margin-top: -1px !important;
          color: var(--themeColor);
          cursor: pointer;
          margin-left: 5px;
          position: relative;
        }

        .inf-label {
          font-weight: 600;
          min-width: 100px;
          max-width: 120px;
          color: var(--textColor);
          font-size: var(--commonFont-medium);
          padding-top: 2px;
          @media only screen and (max-width: 1366px) {
            min-width: 65px;
            max-width: 65px;
          }
        }

        .inf-label-width {
          min-width: 170px;
          max-width: 170px;
        }

        .info-desc {
          max-width: 240px;
          min-width: 240px;
          padding-top: 2px;
          font-size: var(--commonFont-medium);
          color: var(--textColor);
          word-break: break-all;
          i {
            font-size: 16px;
          }
        }

        .email-link {
          color: var(--textColor);
          text-decoration: underline;

          &:hover {
            color: var(--themeColor);
          }
        }

        .copy-icon {
          margin-left: 5px;
          cursor: pointer;
          .component-iconify {
            padding-top: 5px;
          }
          @media only screen and (max-width: 1366px) {
            margin-left: 0px;
          }
          img {
            width: 17px;
            height: 17px;
            @media only screen and (max-width: 1366px) {
              width: 12px;
              height: 12px;
              margin-left: 2px;
            }
          }
        }
        .info-icon {
          position: relative;
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-left: 5px;
          margin-right: 10px;

          .component-iconify {
            color: var(--themeColor);
            width: 18px !important;
            height: 18px !important;
          }

          .custom-tooltip {
            left: 100%;
            right: 0;
            top: -10px;
            writing-mode: unset;
            rotate: unset;
            z-index: 99;
            width: 300px;
            max-width: 300px;

            .tooltip-arrow {
              width: 0;
              height: 0;
              border-left: 6px solid #00000000;
              border-right: 6px solid #000000;
              border-bottom: 6px solid transparent;
              border-top: 6px solid transparent;
              position: absolute;
              top: 100%;
              transform: translateX(-50%);
              opacity: 1;
              margin-left: -5px;
              margin-top: 13px;
              left: 0;
            }

            .tooltip-content {
              font-size: var(--commonFont);
              rotate: unset;
              transform: unset;
              max-width: 300px;
              min-width: 100px;
              overflow: hidden;
              white-space: normal;
              text-align: left;
              padding: 6px;
            }
          }

          &:hover {
            .custom-tooltip {
              display: block;
            }
          }
        }
      }
    }
  }

  .css-1nmdiq5-menu {
    width: 100% !important;
  }

  // .css-b62m3t-container {
  //   .css-13cymwt-control {
  //     .css-1xc3v61-indicatorContainer {
  //       color: var(--whiteColor) !important;
  //     }

  //     .css-1xc3v61-indicatorContainer:hover {
  //       color: var(--whiteColor) !important;
  //     }

  //     // .css-15lsz6c-indicatorContainer {
  //     //   color: var(--whiteColor) !important;
  //     //   opacity: 0 !important;
  //     //   visibility: hidden !important;
  //     // }
  //   }

  //   .css-t3ipsp-control {
  //     .css-1xc3v61-indicatorContainer {
  //       color: var(--whiteColor) !important;
  //     }

  //     .css-1xc3v61-indicatorContainer:hover {
  //       color: var(--whiteColor) !important;
  //     }

  //   }

  //   // .css-1u9des2-indicatorSeparator {
  //   //   display: none !important;
  //   // }
  // }
}


.basic-info-model {
  .card {
    box-shadow: none;
    padding: 0px 0 0;
    margin-top: 7px;
  }
}
// .supplier-basic-info-model{
//   .card {
//     margin-top: 15px;
//   }
// }
.back-button {
  position: absolute;
  right: 20px;
  top: 0;
}

.other-info-tab {
  position: relative;
  margin-top: 2px;
}

.main-tab-header {
  .tab-section .tab-header button:hover {
    background-color: transparent !important;
  }
}

.contact-accrodiaon-scroll {
  // overflow: hidden;
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;

  .card-top-title-btn {
    display: flex;
    width: 100%;
    // padding-bottom: 15px;
    padding-left: 0;
  }

  .card-body-sec {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 285px);
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    padding: 10px;
  }
}
.user-management-history {
  .card {
    margin-bottom: 0;
    .card-body-sec {
      max-height: calc(100vh - 177px);
      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 171px);
      }
      .final-molgrid-section{
        table {
          tbody{
            max-height: calc(100vh - 241px);
            @media only screen and (max-width: 1750px) {
              max-height: calc(100vh - 233px);
            }
          }
        }
      }
      
    }
  }
}
.supplier-contacts {
  .card-body-sec {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 345px);
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    padding: 10px;
  }
}
@media only screen and (max-width: 1750px) {
  .customer-desc-left-sec {
    .card-body-sec {
      .basic-customer-detail {
        .field-desc {
          .info-desc {
            max-width: 180px;
            min-width: 180px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1566px) {
  .customer-desc-left-sec {
    .card-body-sec {
      .basic-customer-detail {
        .field-desc {
          .info-desc {
            max-width: 135px;
            min-width: 135px;
          }

          .inf-label-width {
            min-width: 130px;
            max-width: 130px;
          }
        }
      }
    }
  }

  .customer-desc-left-sec {
    .card-body-sec {
      .basic-customer-detail {
        .profile-info {
          .edit-icons {
            img {
              width: 15px;
            }
          }
        }
      }
    }
  }

  .contact-accrodiaon-scroll {
    .card-body-sec {
      max-height: calc(100vh - 290px);
      // height: calc(100vh - 224px);
      padding: 0;
    }
    &.contact-card-section-new {
      .card-body-sec {
        padding: 10px 10px 0;
      }
    }
  }
}

// New CSS

.info-desc {
  min-width: 0 !important;
  font-size: var(--commonFont-medium);
  // color: #888e98 !important;
}

.email-link {
  text-decoration: none !important;

  &:hover {
    color: var(--themeColor);
  }
}

.field-desc {
  display: flex;
  align-items: center !important;
  margin-bottom: 0px !important;
  margin-left: 7px;
  max-height: 18px !important;
  white-space: nowrap;

  .fa-envelope,
  .fa-globe {
    color: var(--themeColor);
    margin-right: 10px;
    font-size: var(--commonFont-small);
    margin-left: 3px;
    // margin-top: 3px;
  }

  .fa-globe {
    font-size: var(--commonFont) !important;
  }
}

.profile-info {
  position: relative;
  border-bottom: none !important;
  padding: 5px 0px 5px 0px !important;
  margin: 0 !important;
}

.profile-icon {
  min-width: 85px !important;
  min-height: 50px !important;
  max-width: 85px !important;
  // max-height: 50px !important;
  margin-left: 7px;
  overflow: hidden;
  font-size: var(--commonFont-extraSmall) !important;
}

.edit-icons {
  position: absolute;
  top: -5px;
  right: -8px;
  // z-index: 999;
  border-radius: 50%;
  color: var(--whiteColor) !important;
  background: var(--gradient-theme);
  width: 30px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

// .card{
//   margin-top: 10px;
//   padding: 5px 10px;
//   // padding-bottom: 15px;
// }

.edit-icons img {
  filter: brightness(100) !important;
  padding: 6px;
}

.tab-section .tab-header {
  border: none;
}

// .tab-section .tab-header button.active {
//   margin: 0;
// }

.separator {
  position: relative;
}
.separator::after {
  position: absolute;
  height: 65px;
  width: 1px;
  background-color: #bfc1c4;
  content: "";
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
}

.profile-icon-desc {
  align-items: flex-start !important;
}

.info-desc {
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 5px;
  transition: all 0.5s ease; /* Optional: Smooth transition */
}

// .center-container {
//   height: calc(100vh - 80px) !important;
// }

// Responsive CSS

// @media only screen and (max-width: 1400px) {
//   .vertical-tab-inner
//   .tab-section {
//     .tab-header {
//       width: 30% !important;
//     }
//     .collapse-tab{
//       left: calc(30% - 37px) !important;
//     }
//     .collapse-tabs{
//       .tab-header{
//         width: 10px !important;
//       }
//     }
//   }
// }

// Responsive CSS

@media only screen and (max-width: 1200px) {
  .profile-info {
    gap: 2rem !important;
  }
}
@media only screen and (max-width: 1024px) {
  .profile-info {
    gap: 10px !important;
  }
}
