.swal2-styled {
  box-shadow: none;
  background-size: 180% 100% !important;
  transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  -moz-transition: all 0.4s ease-in-out !important;
  -ms-transition: all 0.4s ease-in-out !important;
  min-width: 100px !important;

  &:hover {
    background-image: unset !important;
  }

  &.swal2-confirm {
    color: var(--whiteColor) !important;
    background-image: var(--gradient-theme) !important;
    box-shadow: none !important;

    &:hover {
      color: var(--whiteColor) !important;
      background-image: var(--gradient-theme) !important;
      box-shadow: 0 5px 15px -4px var(--themeColor) !important;
    }
  }

  &.swal2-cancel {
    color: var(--whiteColor) !important;
    background: linear-gradient(to right, #2d3641db, #000000db) !important;
    box-shadow: none !important;

    &:hover {
      background: linear-gradient(to right, #2d3641db, #000000db) !important;
      box-shadow: 0 5px 15px -4px hsla(210, 22%, 4%, 0.75) !important;
    }
  }
}
div:where(.swal2-icon).swal2-question {
  border-color: var(--warningColor) !important;
  color: var(--warningColor) !important;
}

.swal-icon {
  border: 0;
  width: 100px;
}

.swal-textarea {
  margin-top: 15px !important;
  box-shadow: none !important;
  border: 1px solid var(--inputBorder) !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  padding: 10px 8px;
  outline: none;
  width: 100%;
  min-height: 120px;
  font-size: var(--commonFont);
  transition: 0.5s all !important;

  &:focus-visible,
  &:focus {
    box-shadow: none !important;
    border: 1px solid var(--themeColor) !important;
    box-shadow: 0 0 0.25rem 0.05rem rgba(105, 108, 255, 0.1) !important;
    padding-left: 12px !important;
  }
}

.swal-validation-error {
  color: #e30000;
  font-size: var(--commonFont-small);
  font-weight: 500;
  margin: 0 !important;
  text-align: left;
}

@media only screen and (max-width: 1750px) {
  .swal2-styled {
    height: 37px;
  }
}

.swal2-actions {
  button {
    font-size: var(--commonFont-large);
    height: auto !important;
  }
}
