.shorting-section {
  position: relative;

  .shorting-desc-list {
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
    background: var(--whiteColor);
    border-radius: 5px;
    width: 210px;
    position: absolute;
    right: 0;
    top: 100%;
    display: none;
    z-index: 11;

    .short-types {
      .shorting-title {
        padding: 8px 10px;
        background: var(--bg-main-content);
        text-transform: uppercase;
        font-weight: 600;
        font-size: var(--commonFont-small);
        display: flex;
        align-items: center;
      }

      .shorting-inputs {
        .radio {
          padding: 15px 10px;
          .radio-buttons {
            margin: 0;
            margin-bottom: 20px;
            .radio-label {
              font-size: var(--commonFont-small);
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .checkbox-container {
          padding: 15px 10px;
          max-height: 500px;
          overflow: hidden;
          overflow-y: auto;
          position: absolute;
          background: var(--whiteColor);
          box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
          z-index: -1;
          @media screen and (max-width: 1560px) {
            max-height: 400px;
          }
          @media screen and (max-width: 1366px) {
            max-height: 300px;
          }
          .input-checkbox {
            margin-bottom: 10px;
            .checkbox-part {
              align-items: flex-start;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
          .checkbox-part {
            .checkbox input + .checkbox-label:before,
            input[type="checkbox"]:before,
            .checkbox input + .checkbox-label,
            .checkbox {
              max-height: 15px;
              max-width: 15px;
              min-height: 15px;
              min-width: 15px;
            }

            .input-label {
              font-size: var(--commonFont-small);
            }
          }
        }
      }
    }

    &.active {
      display: block;
    }
  }
}
