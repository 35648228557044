.top-header-main {
  position: relative;
  z-index: 9;
  padding: 10px;
  background: var(--bg-main-content);
  .header-section {
    height: 62px;
    width: 100%;
    background: var(--whiteColor);
    display: flex;
    align-items: center;
    padding: 0px 15px 0 10px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
    // position: relative;
    background-color: var(--sidebarColor);
    .left-section {
      width: 40%;
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;

      .email-advan-setting-btn {
        background-color: var(--whiteColor);
        padding: 5px;
        border-radius: 6px;
        color: var(--themeColor);
      }

      .search-bar {
        .command-palette-trigger {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          font-size: var(--commonFont-medium);
          border: 1px solid #dfe2e7;
          // background-color: #f6f7f899;
          color: #5763758f;
          padding: 5px 8px;
          border-radius: 6px;
          width: fit-content;
          box-shadow: rgb(255, 255, 255) 0px 1px 0px inset,
            rgba(232, 234, 238, 0.4) 0px -1px 0px inset,
            rgba(223, 226, 231, 0.5) 0px 1px 2px 0px;
          min-width: 250px;
          cursor: pointer;
          background-color: var(--whiteColor);
          .component-iconify {
            color: var(--themeColor);
          }
          &:hover {
            // background: rgba(232, 234, 238, 0.5);
            border-color: rgb(202, 208, 216);
            box-shadow: none;
          }
          .shortcut-key {
            font-size: var(--commonFont-extraSmall);
            font-weight: bold;
            background-color: var(--whiteColor);
            border: 1px solid var(--cardborderColor);
            padding: 2px 4px;
            border-radius: 5px;
            color: var(--textColor);
          }
        }
      }

      .advance-settings-dropdown-container {
        min-width: 700px;
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
          rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
          rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
          rgba(0, 0, 0, 0.07) 0px 32px 64px;
      }
    }

    .shortcut-list-dropdown {
      min-width: 300px;
      .top-header-card-title {
        padding: 11px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        border-bottom: 1px solid var(--inputBorderLight);

        .title {
          opacity: 0.7;
          font-size: var(--commonFont-large);
        }

        .add-short-cut {
          transition: 0.5s all;
          color: var(--themeColor);

          &:hover {
            color: var(--textColor);
          }

          cursor: pointer;

          i {
            font-size: var(--commonFont-extraLarge);
            margin: 0;
          }
        }
      }

      .short-cuts-list {
        .manus-items {
          border-top: 1px solid var(--inputBorderLight);
          border-bottom: 1px solid var(--inputBorderLight);

          &:first-child {
            border-top: none;
            border-bottom: 0;
          }

          &:last-child {
            border-top: none;
            border-bottom: 0;
          }

          .shortcut-menus {
            border-right: 1px solid var(--inputBorderLight);
            padding: 15px;

            &:nth-child(even) {
              border: none;
            }

            .shortcuts {
              padding: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              cursor: pointer;
              transition: 0.4s all;
              height: 100%;

              .shortcut-icon {
                background: var(--linkMenu);
                height: 40px;
                width: 40px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--textColor);
                &.icon-color {
                  .component-iconify {
                    svg {
                      color: var(--blackColor);
                    }
                  }
                }

                img {
                  width: 20px;
                  height: 20px;
                }
              }

              .shortcut-desc {
                text-align: center;
                margin-top: 10px;

                h6 {
                  font-size: var(--commonFont);
                  margin: 0;
                  font-weight: 600;
                }

                p {
                  margin: 0;
                  font-size: var(--commonFont-small);
                }
              }

              &:hover {
                background: #4359710a;
              }
            }
          }
        }
      }
    }

    .right-section {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: end;

      .shortcut-sec {
        height: 18px;
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        position: relative;

        .header-icon-part {
          .component-iconify {
            width: 23px !important;
            height: 23px !important;
            cursor: pointer;
            svg {
              width: 25px;
              height: 25px;
              color: #fff;
            }
          }
        }

        .shortcut-icon {
          cursor: pointer;
          display: flex;
          align-items: center;
          .component-iconify {
            color: #fff;
            height: 25px;
            width: 25px;
          }
        }
        .header-icon-part {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          .shortcuts-list {
            display: block;
          }
        }
        .component-iconify {
          color: #fff;
        }
      }

      .shortcut-list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        .component-iconify {
          svg {
            color: #fff;
          }
        }
      }

      .search-bar {
        width: 60%;
        margin-right: 25px;

        .search-part {
          width: 100%;
          margin-right: 0;
        }
      }

      .profile-section {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .notification {
          height: 20px;
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          position: relative;

          .bell-icon {
            position: relative;
            cursor: pointer;
            color: #fff;
            display: flex;
            align-items: center;

            .dot-round {
              content: "";
              position: absolute;
              height: 12px;
              width: 12px;
              background: var(--successColor);
              border-radius: 100%;
              -webkit-border-radius: 100%;
              -moz-border-radius: 100%;
              -ms-border-radius: 100%;
              -o-border-radius: 100%;
              top: 0px;
              right: -4px;
              font-size: var(--commonFont-extraSmall);
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
              color: var(--whiteColor);
            }
          }

          .notification-list {
            position: absolute;
            height: 420px;
            width: 300px;
            background: var(--whiteColor);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            top: 100%;
            right: -15px;
            border-radius: 4px;
            display: none;
            z-index: 11;
            overflow: hidden;

            .title-clearall {
              padding: 11px 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 500;
              border-bottom: 1px solid var(--inputBorder);

              .title {
                opacity: 0.5;
                font-size: var(--commonFont-large);
              }

              .clear-all {
                button {
                  background: none;
                  border: none;
                  padding: 0;
                  margin: 0;
                  font: inherit;
                  outline: none;
                  color: var(--themeColor);
                  cursor: pointer;
                  font-weight: 600;
                  transition: 0.5s all;
                  &:hover {
                    color: var(--textColor);
                  }
                }
              }
            }

            .notification-items {
              padding-top: 10px;

              ul {
                list-style-type: none;
                padding-left: 15px;
                padding-right: 15px;
                height: 200px;
                overflow: hidden;
                overflow-y: auto;
                margin: 0;

                li {
                  color: var(--textColor);
                  display: flex;
                  align-items: flex-start;
                  margin-bottom: 10px;
                  cursor: pointer;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  &:nth-child(1) {
                    .noti-img {
                      background: var(--light-red-bg-g);
                    }
                  }

                  &:nth-child(2) {
                    .noti-img {
                      background: var(--warning-bg-g);
                    }
                  }

                  &:nth-child(3) {
                    .noti-img {
                      background: var(--info-bg-g);
                    }
                  }

                  &:nth-child(4) {
                    .noti-img {
                      background: var(--dark-bg-g);
                    }
                  }

                  .noti-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    min-width: 40px;
                    max-width: 40px;
                    height: 40px;
                    margin-right: 10px;
                    opacity: 0.7;

                    i {
                      font-size: var(--commonFont-2extraLarge);
                      line-height: 21px;
                    }
                  }

                  .notification-time {
                    button {
                      background: none;
                      border: none;
                      padding: 0;
                      margin: 0;
                      font: inherit;
                      cursor: pointer;
                      outline: none;
                      font-size: var(--commonFont-small);
                      line-height: normal;
                      color: var(--blackColor) !important;

                      &:hover {
                        color: var(--themeColor) !important;
                      }
                    }

                    .time-sec {
                      color: var(--blackColor);
                      font-size: var(--commonFont-extraSmall);
                      opacity: 0.6;
                    }
                  }
                }
              }
            }

            .notification-footer {
              border-top: 1px solid var(--box-borderColor);
              background: var(--bg-main-content);
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px;
              position: absolute;
              width: 100%;
              bottom: 0;

              button {
                background: none;
                border: none;
                padding: 0;
                margin: 0;
                font: inherit;
                outline: none;
                cursor: pointer;
                font-weight: 600;
                color: var(--themeColor);
                transition: 0.5s all;

                &:hover {
                  color: var(--textColor);
                }
              }
            }
          }

          &:hover {
            .notification-list {
              display: block;
            }
          }
        }

        .profiles {
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;

          &:hover {
            .profile-dropdown-menu {
              display: block;
            }
          }

          .profile-img {
            margin-right: 6px;
            background: #fff;
            color: var(--whiteColor);
            border-radius: 100%;
            font-size: var(--commonFont-extraLarge);
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;

            img {
              width: 30px;
              // filter: brightness(100);
              // -webkit-filter: brightness(100);
            }
          }

          .user-name-desc {
            display: flex;
            align-items: center;

            .name {
              font-weight: 500;
            }

            span {
              img {
                width: 12px;
                filter: brightness(0);
              }
            }
            .component-iconify {
              color: var(--whiteColor);
            }
          }

          .profile-dropdown-menu {
            position: absolute;
            min-width: 180px;
            background: var(--whiteColor);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            border: 1px solid var(--box-borderColor);
            border-radius: 4px;
            top: 102%;
            right: -5px;
            display: none;
            z-index: 99;
            padding-top: 12px;

            .title-list {
              display: flex;
              flex-direction: column;
              align-items: center;
              border-bottom: 1px solid var(--inputBorder);
              padding-bottom: 8px;

              .user-name {
                font-weight: 600;
                color: var(--themeColor);
              }

              &:last-child {
                border-bottom: 0;
              }

              button {
                background: none;
                border: none;
                padding: 0;
                margin: 0;
                font: inherit;
                cursor: pointer;
                outline: none;
                padding: 6px 10px;
                color: var(--textColor);
                width: 100%;
                display: inline-block;

                span {
                  display: flex;
                  transition: 0.3s all;
                  font-weight: 600;
                  width: 100%;

                  &.bi {
                    padding: 5px 10px;
                    font-size: var(--commonFont-large);
                    margin-bottom: 0 !important;
                  }

                  &::before {
                    line-height: inherit;
                    margin-right: 10px;
                    font-size: var(--commonFont-Large);
                  }

                  &.name-title {
                    opacity: 0.5;
                    color: var(--blackColor) !important;
                    cursor: auto;
                  }

                  &.user-name {
                    font-weight: 500;
                    color: var(--blackColor) !important;
                    cursor: auto;
                  }
                }

                &:hover {
                  background: var(--linkMenu);
                }
              }

              &.drop-down-icon-menu {
                padding-bottom: 0;

                a {
                  color: var(--textColor);
                  display: inline-block;
                  padding: 0px 10px;
                  width: 100%;
                  span {
                    font-size: var(--commonFont-large);
                    margin-bottom: 0 !important;
                    padding: 5px 10px;
                  }
                }

                span {
                  margin-bottom: 10px;

                  &:last-child {
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                  }
                }

                &.logout {
                  span {
                    background-color: #ff4c51;
                    color: #fff;
                    box-shadow: 0 0.125rem 0.375rem 0 rgba(255, 76, 81, 0.3);
                    color: #fff !important;
                    border-color: #e64449 !important;
                    border-radius: 4px;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  .header-section {
    height: 40px;
  }
}
