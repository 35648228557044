

.input-group.custom-phone input,
.input-field {
  border: none;
  border: 1px solid var(--inputBorder) !important;
  border-radius: 4px !important;
  width: 100% !important;
  height: 33px !important;
  padding: 0 8px !important;
  font-size: var(--commonFont-medium) !important;
  transition: 0.5s all !important;

  &::placeholder {
    color: var(--placeholderColor);
  }

  &:focus-visible,
  &:focus {
    border: 1px solid var(--themeColor) !important;
    outline-offset: 1px !important;
    box-shadow: 0 0 0.25rem 0.05rem rgba(105, 108, 255, 0.1) !important;
    padding-left: 12px !important;
  }

  &:focus-visible {
    outline: none;
    border-color: var(--themeColor) !important;
    box-shadow: 0 0 0.25rem 0.05rem rgba(105, 108, 255, 0.1) !important;
    padding-left: 12px !important;
  }
}
.input-group.custom-phone input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.inputWithButton {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  border-right: none !important;
  border-right-color: transparent !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.input-button {
  height: 31px;
  border-radius: 4px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.validation-text {
  color: #e30000;
  font-size: var(--commonFont-small);
  font-weight: 500;
  margin: 0 !important;
  // text-transform: capitalize;
}

.horizontal-form {
  .validation-text {
    margin-left: 145px !important;
    margin-top: -8px !important;

    @media only screen and (max-width: 1750px) {
      margin-left: 110px;
    }
  }
}

.validation-star {
  color: #e30000;
  margin-left: 3px;
}

.checkbox-label-part {
  .validation-star {
    display: none;
  }
}

form {
  width: 100%;
}

.input-group {
  position: relative;
  flex-wrap: nowrap !important;

  .icon-fix {
    position: absolute;
    right: 6px;
    top: 58%;
    transform: translateY(-50%);
  }
}

.input-group-append {
  display: flex;
  cursor: pointer;

  .border-fix {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }

  .input-button {
    height: 35px;
    color: var(--whiteColor);
  }

  .list-btn-width {
    min-width: 50px;
  }

  .input-seprate-btn {
    position: relative;

    &:hover {
      .tooltip-text {
        visibility: visible;
        opacity: 1;
      }
    }

    .tooltip-text {
      font-size: var(--commonFont-small);
      visibility: hidden;
      width: 160%;
      background-color: #000000;
      color: var(--whiteColor);
      text-align: center;
      border-radius: 5px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      right: 0;
      top: 40px;
      margin-right: -5px;

      &::after {
        content: "";
        position: absolute;
        top: -10px;
        right: 20px;
        border-width: 6px;
        border-style: solid;
        border-color: #000000 transparent transparent transparent;
        transform: rotate(180deg);
      }
    }
  }
}

.input-icon {
  &:hover {
    .tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }

  i {
    font-size: var(--commonFont-extraLarge);
    color: var(--themeColor);
    cursor: pointer !important;
  }

  .tooltip-text {
    font-size: var(--commonFont-small);
    visibility: hidden;
    width: 220px;
    background-color: #555;
    color: var(--whiteColor);
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    right: 0;
    margin-right: -5px;
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
      content: "";
      position: absolute;
      top: 96%;
      right: 7px;
      border-width: 6px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }
}

.validation-star {
  color: #e30000;
  margin-left: 3px;
}

@media only screen and (max-width: 1750px) {
  .validation-text {
    margin-left: 120px;
    // font-size: var(--commonFont-extraSmall);
  }

  .horizontal-form {
    .input-label-part {
      min-height: 40px;
    }
  }

  .input-group-append {
    .input-button {
      height: 28px;
      padding: 0 5px;
      color: var(--whiteColor);

    }

    .input-seprate-btn {
      &:hover {
        .tooltip-text {
          visibility: visible;
          opacity: 1;
        }
      }

      .tooltip-text {
        font-size: var(--commonFont-small);
        visibility: hidden;
        width: 160%;
        background-color: #555;
        color: var(--whiteColor);
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        left: -16px;
        transition: opacity 0.3s;
        top: 30px;

        &::after {
          content: "";
          position: absolute;
          // top: 12px;
          right: 35px;
          // left: -6px;
          margin-left: -5px;
          border-width: 6px;
          border-style: solid;
          border-color: #555 transparent transparent transparent;
          transform: rotate(180deg);
        }
      }
    }
  }

  .input-group.custom-phone button,
  .input-group.custom-phone input,
  .input-field {
    height: 28px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .css-qbdosj-Input {
    margin: 0px !important;
  }

  .css-1hb7zxy-IndicatorsContainer {
    .css-1xc3v61-indicatorContainer {
      padding: 0 5px !important;
    }
  }
}

.react-international-phone-input-container {
  .react-international-phone-country-selector-button {
    border-color: var(--inputBorder) !important;
    height: 35px;
  }

  .react-international-phone-input {
    width: calc(100% - 36px);
    border-color: var(--inputBorder) !important;
  }
}