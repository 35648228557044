// Toggle Button Start
.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 21px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 2px;
    right: 2px;
    bottom: 0;
    padding: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: var(--themeColor);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--themeColor);
}

input:checked+.slider:before {
    transform: translateX(27px);
    left: -2px;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

// Toggle Button End