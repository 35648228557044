.btn {
  padding: 7px 10px;
  border-radius: 4px;
  text-align: center;
  min-width: 90px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  font-size: var(--commonFont);
  background-size: 180% 100%;
  transition: all 0.4s ease-in-out;
  font-weight: 700 !important;
  color: var(--whiteColor);
  // margin: 0 3px;

  &:hover {
    background-position: 100% 0;
    transition: all 0.4s ease-in-out;
    color: var(--whiteColor);
  }

  img {
    width: 14px;

    // margin: 0 3px;
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
  }

  span {
    &.label-l-space{
      margin-left: 8px;
    }
  }

  &.icon-btn{
    min-width: unset !important;
  }

  &.theme-button {
    background: var(--themeColor);

    &:hover {
      box-shadow: 0 5px 15px -4px var(--themeColor);
      background: var(--theme-btn-hover-bg);
    }
  }

  &.dark-btn {
    background: linear-gradient(to right, #2d3641db, #000000db);

    &:hover {
      box-shadow: 0 5px 15px -4px hsla(210, 22%, 4%, 0.75);
    }
  }

  &.danger-btn {
    // background-image: var(--gradient-danger);
    background: var(--dangerColor);

    &:hover {
      box-shadow: 0 5px 15px -4px hsla(344, 99%, 72%, 0.75);
    }
  }

  &.warning-btn {
    background-image: var(--gradient-warning);

    &:hover {
      box-shadow: 0 5px 15px -4px hsla(50, 100%, 50%, 0.75);
    }
  }

  &.success-btn {
    background-image: var(--gradient-success);

    &:hover {
      box-shadow: 0 5px 15px -4px hsla(171, 93%, 42%, 0.75);
    }
  }

  &.info-btn {
    background-image: var(--gradient-info);

    &:hover {
      box-shadow: 0 5px 15px -4px hsla(207, 96%, 45%, 0.75);
    }
  }

  &.outline-dark-btn {
    border: 1px solid var(--inputBorder);
    color: var(--textColor);
    border-radius: 5px;

    img {
      filter: unset;
    }

    &:hover {
      box-shadow: 0 5px 15px -4px var(--inputBorder);
    }
  }

  &.outline-theme-btn {
    border: 1px solid var(--themeColor);
    color: var(--themeColor);
    border-radius: 5px;

    img {
      filter: unset;
    }

    &:hover {
      box-shadow: 0 5px 15px -4px var(--themeColor);
    }
  }


  &.disable-btn {
    min-width: 100px;
    // background: #1b54c2;
    color: var(--whiteColor);
    cursor: not-allowed !important;
    pointer-events: unset;

    @media only screen and (max-width: 1750px) {
      min-width: 60px;
    }
  }
  span{
    order: 2;
  }
  .component-iconify{
    margin-right: 3px;
    order: 1;
  }
}

.button-loading-part {
  content: "";
  width: 16px;
  height: 16px;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: var(--whiteColor);
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.btn-next img {
  margin-left: 8px !important;
  margin-right: 0 !important;
}

.btn-prev img {
  margin-right: 8px !important;
  margin-left: 0 !important;
  rotate: 180deg;
}

@media only screen and (max-width: 1750px) {
  .btn {
    height: 28px;
    font-size: var(--commonFont-small);
    min-width: 60px;

    img {
      width: 10px;
    }
  }

  .btn-next img {
    margin-left: 5px !important;
    margin-right: 0 !important;
  }

  .btn-prev img {
    margin-right: 5px !important;
    margin-left: 0 !important;
    rotate: 180deg;
  }
}


.btn-tooltip {

  .tooltip-show {
    top: 100%;
  }

  .tooltip-arrow-icon {
    top: 29px;
    right: 5px;
    left: unset;
    transform: translateX(-50%);
    rotate: 360deg;
    opacity: 0;
    
    @media only screen and (max-width: 1750px) {
      top: 23px;
    }
  }
}

.btn-height{
  height: 28px;
}