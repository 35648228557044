// Radio Start
.radio-buttons {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  .radio-label{
    word-break: normal;
    white-space: nowrap;
    margin-left: 0;
    margin-right: 15px;
  }
  input {
    position: absolute;
    opacity: 0;

    +.radio-label {
      &:before {
        content: '';
        background: var(--whiteColor);
        border-radius: 100%;
        border: 1px solid var(--inputBorder);
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        top: 0;
        margin-right: 7px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    &:checked {
      +.radio-label {
        &:before {
          background: var(--gradient-theme);
          box-shadow: inset 0 0 0 3px var(--whiteColor);
          border-color: var(--themeColor);
        }
      }
    }

    &:focus {
      +.radio-label {
        &:before {
          outline: none;
          border-color: var(--themeColor);
        }
      }
    }

    &:disabled {
      +.radio-label {
        &:before {
          box-shadow: inset 0 0 0 3px var(--whiteColor);
          border-color: var(--whiteColor);
          background: var(--whiteColor);
        }
      }
    }

    +.radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
.radio {
  label {
    display: flex;
    align-items: center;
    line-height: normal;
  }

  input {
    position: absolute;
    opacity: 0;

    +.radio-label {
      &:before {
        content: '';
        background: var(--whiteColor);
        border-radius: 100%;
        border: 1px solid var(--inputBorder);
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        top: 0;
        margin-right: 7px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    &:checked {
      +.radio-label {
        &:before {
          background-color: #075dde;
          box-shadow: inset 0 0 0 4px var(--whiteColor);
        }
      }
    }

    &:focus {
      +.radio-label {
        &:before {
          outline: none;
          border-color: #075dde;
        }
      }
    }

    &:disabled {
      +.radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px var(--whiteColor);
          border-color: var(--whiteColor);
          background: var(--whiteColor);
        }
      }
    }

    +.radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
.radio-label-sec{
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
// Radio End
.horizontal-form {
.radio-label-part{
  display: flex;
    align-items: center;
    width: 100%;
    .input-label-title{
      min-width: 120px;
    }
}

}

@media only screen and (max-width: 1750px) {
  .radio {
    input {
      position: absolute;
      opacity: 0;

      +.radio-label {
        &:before {
          vertical-align: middle;
          width: 14px;
          height: 14px;
          margin-right: 3px;
        }
      }
    }
  }
}