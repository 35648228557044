.stepper-card {
  overflow: hidden;
  width: 100%;

  .stepper-section {
    .stepper-header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--linkMenu);
      padding: 8px 12px;
      width: 100%;

      .step {
        display: flex;
        align-items: center;
        margin-right: 8px;
        opacity: 0.7;

        .step-button {
          background: none;
          display: flex;
          align-items: center;
          outline: none !important;
          border: none !important;
          box-shadow: none !important;

          .stepper-box {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 35px;
            min-width: 35px;
            max-height: 35px;
            max-width: 35px;
            background: var(--linkMenu);
            font-weight: 600;
            font-size: var(--commonFont-large);
            border-radius: 4px;
            margin-right: 12px;
            color: var(--textColor);
          }

          .stepper-label {
            display: flex;
            text-align: left;
            justify-content: flex-start;
            flex-direction: column;

            span {
              font-size: var(--commonFont-large);
              font-weight: 500;
              line-height: 125%;
              color: var(--textColor) !important;

              &.small-txt {
                font-size: var(--commonFont-medium);
                font-weight: normal;
                margin-top: 2px;
                font-weight: 400;
                opacity: 0.8;
              }
            }
          }
        }

        &.active {
          opacity: 1;

          .step-button {
            .stepper-box {
              background: var(--themeColor);
              color: var(--whiteColor);
              box-shadow: 0 0.1875rem 0.375rem 0 rgba(105, 108, 255, 0.4);
            }

            .stepper-label {
              span {
                color: var(--themeColor);

                &.small-txt {
                  color: var(--textColor);
                }
              }
            }
          }
        }
      }

      .right-arrow {
        margin-right: 15px;

        img {
          width: 12px;
          transform: rotate(270deg);
          filter: contrast(0);
        }

        svg {
         
          transform: rotate(270deg);
          filter: contrast(0);
        }
      }
    }

    .stepper-content {
      padding: 12px 24px 12px 24px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      overflow-y: auto;
      width: 100%;
      min-height: calc(100vh - 185px);
      max-height: calc(100vh - 185px);

      @media only screen and (max-width: 1750px) {
        padding: 10px 15px;
        max-height: calc(100vh - 175px);
        min-height: calc(100vh - 175px);
      }

      @media only screen and (max-width: 1650px) {
        max-height: calc(100vh - 145px);
        padding-bottom: 15px;
      }

      @media only screen and (max-width: 1450px) {
        max-height: calc(100vh - 165px);
      }

      .content {
        display: none;

        &.active {
          display: block;
        }
      }

      .record-not-found {
       

        h5 {
          font-size: var(--commonFont-large);
        }
      }
    }
    .stepper-header-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--linkMenu);
      padding: 8px 12px 8px 12px;
      width: 100%;
      background-color: var(--themeColorLight);
      margin: 10px 0 0;
      border-radius: 8px;
      .detail-info {
        .detail-title {
          font-weight: 700;
        }
        .detail-title-value {
          font-size: var(--commonFont-medium);
          margin-left: 5px;
        }
      }
    }
  }
}

.supplier-search-table {
  .card {
    margin-bottom: 0 !important;
    .final-molgrid-section {
      table {
        tbody {
          max-height: calc(100vh - 143px);
          @media only screen and (max-width: 1750px) {
            max-height: calc(100vh - 137px);
          }
          @media only screen and (max-width: 1560px) {
            max-height: calc(100vh - 155px);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  .stepper-card {
    .card {
      padding: 0px 5px 15px;
      margin-bottom: 0;

      .card {
        padding: 0;
      }
    }

    .stepper-section {
      .stepper-header {
        padding-bottom: 9px;

        .step {
          .step-button {
            .stepper-box {
              min-height: 32px;
              min-width: 32px;
              max-height: 32px;
              max-width: 32px;
              font-size: var(--commonFont);
            }

            .stepper-label {
              span {
                font-size: var(--commonFont);

                &.small-txt {
                  font-size: var(--commonFont-small);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .stepper-card {
    .stepper-section {
      .stepper-content {
        max-height: calc(100vh - 155px);
      }
    }
  }
}


.stepper-view {
  .supplier-contacts {
    .card-body-sec {
      max-height: calc(100vh - 195px);
      overflow: hidden;
    }
  }

  .vertical-tab-inner {
    .tab-section .tab-content {
      max-height: calc(100vh - 400px);
    }
  }

  .card {
    max-height: calc(100vh - 93px);
    margin-bottom: 10px;

    .stepper-content {
      padding-bottom: 0;

      @media only screen and (max-width: 1650px) {
        padding-bottom: 15px;
      }
    }

    .card {
      max-height: calc(100vh - 245px);

      .card {
        max-height: calc(100vh - 260px);

        @media only screen and (max-width: 1280px) {
          max-height: calc(100vh - 45px);
          overflow: hidden;
          overflow-y: auto;
        }
      }
    }
  }

  .model-content {
    .model-body {
      .card {
        max-height: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  .stepper-view {
    .card {
      max-height: calc(100vh - 83px);

      .card {
        max-height: calc(100vh - 210px);
      }
    }
  }

  @media only screen and (max-width: 1650px) {
    .stepper-view {
      .card {
        .card {
          max-height: calc(100vh - 180px);
        }
      }
    }
  }

  .stepper-view-supplier {
    .card {
      max-height: calc(100vh - 145px);

      .card {
        max-height: calc(100vh - 255px);
      }
    }
  }
}

@media only screen and (max-width: 1650px) {
  .stepper-view {
    .card {
      .card {
        max-height: unset;
      }
    }
  }
}

@media only screen and (max-width: 1566px) {
  .stepper-view {
    .stepper-section {
      .stepper-content {
        max-height: calc(100vh - 170px);
        padding-bottom: 0;
      }
    }

    .card {
      .card {
        max-height: unset;
        margin-bottom: 0;

        .card {
          max-height: unset;
          margin-bottom: 0;
        }
      }
    }
  }
}

.stepper-view-supplier {
  .supplier-contacts {
    .card-body-sec {
      max-height: calc(100vh - 195px);
    }
  }

  .vertical-tab-inner {
    .tab-section {
      max-height: calc(100vh - 285px);

      .tab-content {
        max-height: unset;
      }
    }
  }

  .card {
    max-height: calc(100vh - 325px);

    @media only screen and (max-width: 1750px) {
      max-height: calc(100vh - 245px);
    }

    .card {
      max-height: calc(100vh - 310px);
    }
  }

  .model-content {
    .model-body {
      .card {
        max-height: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .stepper-view {
    .card {
      .card {
        max-height: calc(100vh - 54px);
      }
    }
  }

  .stepper-card {
    .stepper-section {
      .stepper-content {
        .content {
          &.active {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.stepper-view-supplier {
  .financial-setting {
    .card {
      max-height: 100%;
      min-height: 103px;
    }

    .address-main-card-sec {
      .payment-method-sec {
        margin-top: -20px;
        max-height: calc(100vh - 360px);
        overflow: hidden;

        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 305px);
        }

        .tab-content {
          padding-bottom: 85px;

          @media only screen and (max-width: 1750px) {
            max-height: calc(100vh - 350px);
            padding-bottom: 22px;
          }

          .ach-wire-section {
            .centered {
              justify-content: center !important;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}

.supplier-f {
  &.financial-setting {
    .card {
      max-height: 100%;
      min-height: 93px;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

// Responsive CSS

@media only screen and (max-width: 1272px) {
  .stepper-card {
    .stepper-section {
      .stepper-header {
        overflow: hidden;
        overflow-x: auto;
        width: 100%;

        .step {
          min-width: 180px;
        }
      }

      ::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: var(--inputBorder);
      }

      ::-webkit-scrollbar-thumb {
        background: var(--gradient-theme);
      }
    }
  }
}
