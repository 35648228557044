.config-card {
  .card {
    height: calc(100vh - 140px);
    overflow: hidden;
    width: 100%;

    .card-body-sec {
      margin: 0;
    }
  }

  .config-content {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 210px);
    overflow: hidden;
    overflow-y: auto;
    width: 100%;

    .config-module-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 -5px 0px;
      padding: 7px 15px;
      cursor: pointer;
      border-bottom: 1px solid #ebebeb;

      &:hover {
        background-color: var(--linkMenu);
      }

      &.selected {
        background-color: var(--themeColorLight);

        span {
          color: var(--themeColor);
        }
      }

      span {
        color: var(--textColor);
        font-weight: 500;
      }

      .fa {
        transform: rotate(-90deg);
        position: absolute;
        right: 4px;
        font-size: var(--commonFont-extraLarge);
        color: var(--themeColor);
      }
    }

    .config-functionalities-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 -5px 0px;
      padding: 7px 10px;
      cursor: pointer;
      border: 1px solid #ebebeb;
      border-radius: 5px;

      &:hover {
        background-color: var(--linkMenu);
      }

      &.selected {
        background-color: var(--themeColorLight);

        span {
          color: var(--themeColor);
        }
      }

      .profile-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: 1px solid var(--themeColor);
        color: var(--themeColor);
        border-radius: 100%;
        box-shadow: var(--card-shadow);

        .fa {
          font-size: var(--commonFont-extraLarge);
        }
      }

      span {
        margin-left: 10px;
        color: var(--textColor);
        font-weight: 500;
      }

      .right-dropdown {
        transform: rotate(-90deg);
        position: absolute;
        right: 4px;
        font-size: var(--commonFont-extraLarge);
        color: var(--themeColor);
      }
    }

    .config-rule-info {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 5px 0;
      padding: 7px 10px;
      border: 1px solid var(--themeColor);
      border-radius: 5px;
      position: relative;

      .profile-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background: var(--gradient-theme);
        border-radius: 100%;
        color: var(--whiteColor);
        font-size: var(--commonFont-large);
        box-shadow: var(--card-shadow);
      }

      span {
        color: var(--textColor);
        font-weight: 500;
      }

      .edit-icons {
        background: none;
        cursor: pointer !important;
        padding: 0 !important;
        top: 0;
        right: 0;

        img {
          width: 20px !important;
          filter: none !important;
          padding: 0 !important;

          @media only screen and (max-width: 1750px) {
            width: 15px !important;
          }
        }
      }
    }
  }
}

.email-template {
  .card {
    padding-top: 0;
    margin-bottom: 0;
  }
  .email-template-grid {
    table {
      tbody {
        max-height: calc(100vh - 263px);
        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 252px) !important;
        }
      }
    }
  }
}
.email-action{
  .card {
    padding-top: 0;
    margin-bottom: 0;
  }
  .dictionary-grid-list{
    table {
      tbody {
        max-height: calc(100vh - 263px);
        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 249px) !important;
        }
      }
    }
  }
}
.dictionary {
  .card {
    padding-top: 0;
    margin-bottom: 0;
  }
  .dictionary-grid-list {
    table {
      tbody {
        max-height: calc(100vh - 257px);
        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 248px) !important;
        }
      }
    }
  }
}
.snippets {
  .card {
    padding-top: 0;
    margin-bottom: 0;
  }
  .snippets-list-grid {
    table {
      tbody {
        max-height: calc(100vh - 263px);
        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 252px) !important;
        }
      }
    }
  }
}
.system-constraints{
  .card {
    padding-top: 0;
    margin-bottom: 0;
  }
  .dictionary-grid-list {
    table {
      tbody {
        max-height: calc(100vh - 263px);
        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 249px) !important;
        }
      }
    }
  }
}
.order-badge-card-sec {
  .card {
    margin-bottom: 0;
    padding-top: 0;
  }

  .badges-list-grid {
    .final-molgrid-section {
      table {
        tbody {
          max-height: calc(100vh - 253px);

          @media only screen and (max-width: 1780px) {
            max-height: calc(100vh - 249px);
          }

          tr {
            td {
              padding: 8px 0 8px 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  .config-card {
    .card {
      height: calc(100vh - 120px);
      margin-bottom: 0;
    }

    .config-content {
      max-height: calc(100vh - 180px);
    }
  }
}

.api-provider {
  .card {
    padding-top: 0;
    .card-top-title-btn {
    }
  }
  table {
    tbody {
      max-height: calc(100vh - 263px);

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 253px) !important;
      }
    }
  }
}

.api-parameter-provider{
  table {
    tbody {
      max-height: calc(100vh - 320px) !important;
      @media only screen and (max-width: 1560px) {
        max-height: calc(100vh - 290px) !important;
      }
    }
  }
}

.approval-rules {
  .card {
    margin-bottom: 0;
    padding-top: 0;
  }
  table {
    tbody {
      max-height: calc(100vh - 268px);

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 252px) !important;
      }
    }
  }
  // .css-1u9des2-indicatorSeparator {
  //   margin: 0px 0 -4px 0 !important;
  //   @media only screen and (max-width: 1750px) {
  //     margin: 0px 0 -1px 0 !important;
  //   }
  // }
  // @media only screen and (max-width: 1750px) {
  //   .css-1wy0on6 {
  //     height: 25px !important;
  //     min-height: 25px !important;
  //   }
  // }
  // .css-tj5bde-Svg {
  // }
}
.custom-forms {
  .card {
    margin-bottom: 0;
    padding-top: 0;

  }
}
.third-party-api-provider {
  table {
    tbody {
      max-height: calc(100vh - 235px);

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 190px) !important;
      }
    }
  }
}
.system-event {
  .card {
    padding-top: 0;
    margin-bottom: 0;
  }
  table {
    tbody {
      max-height: calc(100vh - 206px);

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 197px) !important;
      }
      @media only screen and (max-width: 1560px) {
        max-height: calc(100vh - 215px) !important;
      }
    }
  }
}

.functional-config {
  .card {
    padding-top: 0;
  }
  table {
    tbody {
      max-height: calc(100vh - 320px) !important;

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 310px) !important;
      }
    }
  }
}

.view-function-provider {
  table {
    tbody {
      max-height: calc(100vh - 365px) !important;

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 325px) !important;
      }
    }
  }
}

.customer-detail-tab-sec {
  .contact-accrodiaon-scroll {
    .card-body-sec {
      max-height: calc(100vh - 255px);
      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 305px);
      }
    }
  }

  .api-management {
    table {
      tbody {
        max-height: calc(100vh - 395px) !important;

        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 356px) !important;
        }

        @media only screen and (max-width: 1650px) {
          max-height: calc(100vh - 336px) !important;
        }

        @media only screen and (max-width: 1280px) {
          max-height: calc(100vh - 348px) !important;
        }
      }
    }
  }
}
.main-page-layout {
  .middle-page-section {
    .center-content-part {
      .content-desc-section {
        .center-container {
          max-height: calc(100vh - 70px) !important;

          @media only screen and (max-width: 1750px) {
            // max-height: calc(100vh - 65px) !important;
          }

          // @media only screen and (max-width: 1650px) {
          //   max-height: calc(100vh - 336px) !important;
          // }

          // @media only screen and (max-width: 1280px) {
          //   max-height: calc(100vh - 348px) !important;
          // }
        }
      }
    }
  }
}
