.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 8px;
  border: 2px solid transparent;

  &--disabled {
    cursor: not-allowed;
    opacity: 0.2;
    background-color: var(--borderColor);

    &:hover {
      background-color: var(--borderColor);
    }
  }

  &--no-elevation {
    box-shadow: none;
  }

  /*********************Icon Button shape (Start)******************************/

  &--circle {
    border-radius: 50%;
  }

  &--square {
    border-radius: 5px;
  }

  /*********************Icon Button shape (End)******************************/

  /*********************Icon Button Types with Color (Start)******************************/
  &--text {
    background: none;

    &--primary {
      color: var(--themeColor);
      &:hover {
        background: var(--theme-btn-hover-bg);
      }
    }

    &--secondary {
      color: var(--secondaryColor);
      &:hover {
        background: var(--secondaryColor-Lighter);
      }
    }

    &--success {
      color: var(--successColor);
      &:hover {
        background: var(--successColor-Lighter);
      }
    }

    &--error {
      color: var(--errorColor);
      &:hover {
        background: var(--errorColor-Lighter);
      }
    }

    &--info {
      color: var(--infoColor);
      &:hover {
        background: var(--infoColor-Lighter);
      }
    }

    &--warning {
      color: var(--warningColor);
      &:hover {
        background: var(--warningColor-Lighter);
      }
    }
  }

  &--contained {
    color: var(--whiteColor);

    &--primary {
      background: var(--themeColor);
      &:hover {
        box-shadow: 0 5px 15px -4px var(--themeColor);
        background: var(--theme-btn-hover-bg);
      }
    }

    &--secondary {
      background: var(--secondaryColor);
      &:hover {
        background: var(--secondaryColor-Dark);
      }
    }

    &--success {
      background: var(--successColor);
      &:hover {
        background: var(--successColor-Dark);
      }
    }

    &--error {
      background: var(--errorColor);
      &:hover {
        background: var(--errorColor-Dark);
      }
    }

    &--info {
      background: var(--infoColor);
      &:hover {
        background: var(--infoColor-Dark);
      }
    }

    &--warning {
      background: var(--warningColor);
      &:hover {
        background: var(--warningColor-Dark);
      }
    }
  }

  &--outlined {
    background: none;

    &--primary {
      border: 2px solid var(--themeColor);
      color: var(--themeColor);
      &:hover {
        background: var(--theme-btn-hover-bg);
      }
    }

    &--secondary {
      border: 2px solid var(--secondaryColor);
      color: var(--secondaryColor);
      &:hover {
        background: var(--secondaryColor-Lighter);
      }
    }

    &--success {
      border: 2px solid var(--successColor);
      color: var(--successColor);
      &:hover {
        background: var(--successColor-Lighter);
      }
    }

    &--error {
      border: 2px solid var(--errorColor);
      color: var(--errorColor);
      &:hover {
        background: var(--errorColor-Lighter);
      }
    }

    &--info {
      border: 2px solid var(--infoColor);
      color: var(--infoColor);
      &:hover {
        background: var(--infoColor-Lighter);
      }
    }

    &--warning {
      border: 2px solid var(--warningColor);
      color: var(--warningColor);
      &:hover {
        background: var(--warningColor-Lighter);
      }
    }
  }
  /*********************Icon Button Types with Color (End)******************************/

  /*********************Icon Button Size (Start)******************************/

  &--small {
    padding: 6px;
    width: 35px;
    height: 35px;
    @media only screen and (max-width: 1750px) {
      width: 28px;
      height: 28px;
    }
    .icon-button__icon {
      width: 20px !important;
      height: 20px !important;
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  &--medium {
    padding: 8px;
    width: 40px;
    height: 40px;
    .icon-button__icon {
      width: 24px !important;
      height: 24px !important;
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  &--large {
    padding: 10px;
    width: 45px;
    height: 45px;

    .icon-button__icon {
      width: 28px !important;
      height: 28px !important;
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  /*********************Icon Button Size (End)******************************/

  .icon-button__icon {
    width: 20px;
    height: 20px;
  }
}

/*********************Icon Button Size for anchor tag button (Start)******************************/

/* Target the anchor tag specifically */
a.icon-button {
  text-decoration: none; /* Optional: If you want to remove underline */
}

a.icon-button {
  &--small {
    padding: 6px;
    width: 20px;
    height: 20px;
    .icon-button__icon {
      width: 20px !important;
      height: 20px !important;
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  &--medium {
    padding: 8px;
    width: 20px;
    height: 20px;
    .icon-button__icon {
      width: 24px !important;
      height: 24px !important;
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  &--large {
    padding: 10px;
    width: 30px;
    height: 30px;
    .icon-button__icon {
      width: 28px !important;
      height: 28px !important;
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  /* Additional styles if necessary */
  &--no-elevation {
    box-shadow: none;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

/*********************Icon Button Size for anchor tag button (End)******************************/
