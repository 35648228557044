@import "./Variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

/* // Common Styles Start */

body {
  padding: 0;
  margin: 0;
  background-color: #0004430d;
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: var(--commonFont);
  color: var(--textColor);
  overflow: hidden;
}

a {
  transition: 0.5s all;
  color: var(--themeColor);
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
  text-decoration: none;

  &:hover {
    color: var(--darkColor);
  }
}

.theme-color {
  color: var(--themeColor);
}

.input-label-part {
  width: 100%;
  position: relative;
}

.input-label {
  line-height: inherit;
  display: inline-block;
  margin-bottom: 2px;
  font-size: var(--commonFont);
  color: var(--textColor);
  // color: #686572;
  font-weight: 500;
  // text-transform: uppercase;
  // min-height: 35px;
  display: flex;
  align-items: center;
}

.label-h-0 {
  .input-label-part {
    .input-label-title {
      .input-label {
        min-height: unset;
      }
    }
  }
}

.checkbox-part {
  .input-label {
    text-transform: capitalize !important;
  }
}

// custom-margin start
.m-0 {
  margin: 0 !important;
}

// Margin Left
.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

// Margin-Right
.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mt-0 {
  margin-top: 0;
}

// Margin-top
.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

// Margin-bottom
.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

// custom-margin end

// custom-padding start
.p-0 {
  padding: 0 !important;
}

// Padding Left
.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

// Padding-Right
.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

// Padding-top
.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

// Padding-bottom
.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

// custom-padding end

.chip-red {
  color: #dc3545;
}

.chip-green {
  color: #28a745;
}

.themeColor {
  color: var(--themeColor);
}

// Common Styling Start

.main-page-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--bg-main-content);

  .top-sec {
    display: flex;
    overflow: hidden;
  }

  .collapse-btn {
    position: absolute;
    padding: 7px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #ffffffc0;
    top: 60px;
    backdrop-filter: blur(10px);
    right: -20px;
    z-index: 99;

    .click-btn {
      background: var(--themeColor);
      height: 100%;
      width: 100%;
      border-radius: 100%;
      padding: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        filter: brightness(100);
        transform: rotate(90deg);
        transition: 0.5s all;
      }
    }
  }

  .middle-page-section {
    width: calc(100% - 220px);
    padding: 0;
    margin: 0 auto;
    transition: 0.5s all;

    @media only screen and (max-width: 1750px) {
      width: calc(100% - 225px);
    }

    .center-content-part {
      width: 100%;
    }

    .center-content-part {
      // height: calc(100vh - 62px);
      height: 100vh;
      overflow: hidden;

      .content-desc-section {
        .center-container {
          height: calc(100vh - 47px);
          overflow: hidden;
          overflow-y: auto;
          margin-bottom: 0;
          padding-bottom: 6px;

          @media only screen and (max-width: 1750px) {
            height: calc(100vh - 26px);
          }
        }
      }
    }
  }

  &.collapsed {
    .top-sec {
      .sidebar-section {
        width: 85px;

        .sidebar {
          .main-menus {
            .sidebar-brand .brand-name {
              margin-top: -5px;
            }

            .sidebar-brand {
              font-size: var(--commonFont-extraLarge);
              gap: 10px;
              flex-direction: column;

              .small-sidebar {
                display: none;
              }

              .sidebar-user {
                bottom: 0%;
              }
              .profile-icon {
                min-width: 50px !important;
                min-height: 30px !important;
                max-width: 55px !important;
              }
            }

            .sidebar-menu {
              ul {
                &.sidebar-menu-list {
                  .menu-group {
                    position: relative;
                    margin: 15px 0;

                    &::before {
                      content: "";
                      height: 1px;
                      width: 30px;
                      transform: translate(42%);
                      background-color: #e6e6e8;
                      position: absolute;
                      top: -9px;
                    }

                    .group-label {
                      display: none;
                    }
                  }

                  li {
                    &.menu-item {
                      position: relative;

                      a {
                        justify-content: center;
                        padding: 10px;

                        &.menu-arrow {
                          &::before {
                            display: block;
                            opacity: 1;
                            right: -8px;
                            width: 10px;
                          }
                        }

                        span {
                          display: none;
                          opacity: 0;
                        }
                      }

                      .sidebar-dropdown {
                        display: none;
                        opacity: 0;
                        transition: all 1s ease-in-out;
                        height: 0;
                      }

                      &.active-menu {
                        &:hover {
                          .sidebar-dropdown {
                            display: block;
                            opacity: 1;
                            position: absolute;
                            z-index: 999;
                            transition: all 1s ease-in-out;
                            min-width: 245px;
                            background: transparent;
                            left: 40px;
                            top: 0;
                            height: auto;

                            .collapse-dropdown {
                              background-color: #2f3349af;
                              color: var(--whiteColor) !important;
                              border-radius: 8px;
                              backdrop-filter: blur(15px);
                              // opacity: 0;

                              a {
                                padding: 10px 0px 10px 20px !important;
                                margin: 0 !important;
                              }
                            }
                          }
                        }
                      }
                    }

                    &.active-menu {
                      a::before {
                        transform: translateY(-50%) rotate(-90deg);
                      }

                      ul {
                        li {
                          &::before {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .collapse-btn {
          opacity: 1;

          .click-btn {
            svg {
              transform: rotate(270deg);
            }
          }
        }
      }

      .middle-page-section {
        width: calc(100% - 60px);
      }
    }
  }
}

@media only screen and (max-width: 1560px) {
  .customer-financial-setting-page {
    max-height: 410px;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 10px;
  }
}

.page-title {
  h2 {
    font-size: var(--commonFont-2extraLarge);
    font-weight: bold;
    color: var(--themeColor);
  }
}

.horizontal-form {
  .input-label-part {
    display: flex;
    align-items: flex-start;
    min-height: 50px;

    .input-label-title {
      min-width: 130px;
      max-width: 130px;
    }

    &.tiny-editor {
      align-items: flex-start;
      margin-bottom: 10px;
      // .input-label-title {
      //   min-width: unset;
      // }
    }
  }

  .input-field-sec {
    &.text-area {
      .input-label-part {
        display: flex;
        align-items: flex-start;
        margin-bottom: 2px;
      }
    }

    .checkbox-label-part {
      margin-left: 148px;
    }
  }

  .margin-left0-checkbox {
    .input-field-sec {
      .checkbox-label-part {
        margin-left: 0;
      }

      .validation-text {
        margin-left: 0 !important;
        // text-transform: capitalize;
      }
    }
  }

  .date-picker-label-part {
    display: flex;
    align-items: center;
    min-height: 50px;

    .input-label-part {
      min-width: 120px;
    }

    .date-picker {
      width: 100%;
    }
  }

  &.add-edit-user-form {
    .input-label-part {
      .input-label-title {
        min-width: 70px;
        max-width: 70px;
      }
    }

    .validation-text {
      margin-left: 85px !important;
    }

    .input-field-sec {
      .checkbox-label-part {
        margin-left: 82px;
      }
    }
  }

  &.add-role-input {
    .input-label-part {
      .input-label-title {
        min-width: 70px;
        max-width: 70px;
      }
    }

    .validation-text {
      margin-left: 85px !important;
    }
  }

  &.assign-user-form {
    .input-label-part {
      .input-label-title {
        min-width: 50px;
        max-width: 50px;
      }
    }

    .validation-text {
      margin-left: 65px !important;
    }
  }

  &.basic-info-step {
    .input-label-part {
      .input-label-title {
        min-width: 100px;
        max-width: 100px;
      }

      .input-group {
        flex-wrap: nowrap;
      }
    }

    .validation-text {
      margin-left: 112px !important;
    }
  }
}

.basic-info-sec {
  &.half-sec {
    .card-section-left {
      margin-bottom: 0;
    }
  }
}

.card {
  padding: 10px;
  background: var(--whiteColor);
  border-radius: 12px;
  margin-bottom: 20px;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);

  &.card-px-0 {
    padding: 12px 0;
  }

  .card {
    box-shadow: none;
    border: none;
    padding: 0;
    margin-bottom: 0;
  }

  .card-top-title-btn {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-height: 40px;

    &::before {
      position: absolute;
      content: "";
      height: 1px;
      width: 100%;
      background: var(--linkMenu);
      left: 0;
      bottom: 0px;
    }

    .btn-right-sec {
      button {
        margin-left: 12px;
        min-width: 28px;
        padding: 5px 10px;

        .component-iconify {
          width: 15px !important;
          height: 15px !important;
          margin-right: 0;
        }
      }
    }
    .card-title-btn-part {
      .btn {
        padding: 5px 10px;
      }
    }
  }
  .card-title-btn-right-part {
    &.last-btn {
      button {
        min-width: 28px;
        min-height: 28px;
        img {
          max-width: 20px;
        }
      }
    }
  }

  .card-title,
  .section-title {
    margin-bottom: 0;

    h4 {
      color: var(--textColor);
      color: var(--themeColor);
      margin-bottom: 0;
      text-transform: capitalize;
      font-size: var(--commonFont-large);
      font-weight: 600;
      white-space: nowrap;
    }

    p {
      font-size: var(--commonFont);
      margin-bottom: 0;
      font-weight: normal;
      color: var(--textColorLight);

      span {
        color: var(--dangerColor);
      }
    }
  }

  .card-title {
    &.border-b {
      padding-bottom: 10px;
      border-bottom: 1px solid var(--inputBorder);
    }
  }

  &.card-section-left {
    .card-top-title-btn {
      justify-content: space-between;
    }
  }

  &.card-section-between {
    .card-top-title-btn {
      justify-content: space-between;
    }
  }

  &.card-button-only {
    .card-top-title-btn {
      justify-content: space-between;
    }
  }

  .card-body-sec {
    margin-top: 10px;
  }
}
.address-main-card-sec,
.contact-main-card-section,
.notes-main-card-section {
  .card {
    .card-top-title-btn {
      .btn-right-sec {
        .card-title-btn-right-part {
          button {
            min-width: 28px;
            max-width: 28px;
            min-height: 28px;
            min-height: 28px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}

.role-table-sec {
  table {
    thead {
      tr {
        th {
          &:nth-child(2) {
            text-align: center;
            width: 200px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:nth-child(2) {
            text-align: center;
            width: 200px;
          }
        }
      }
    }
  }
}

.card-bottom-m-0 {
  // max-height: calc(100vh - 162px);
  // overflow: hidden;
  // overflow-y: auto;

  .card {
    margin-bottom: 0;
  }
}

.address-main-card-sec {
  .card {
    padding-top: 0;
    max-height: calc(100vh - 265px);

    @media only screen and (max-width: 1750px) {
      padding-top: 7px;
      max-height: calc(100vh - 225px);
    }

    @media only screen and (max-width: 1366px) {
      max-height: calc(100vh - 200px);
    }
    .card-body-sec {
      margin-top: 0;
    }
  }
  &.supplier-f {
    .card {
      padding-top: 8px;
      padding-bottom: 18px;

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 357px);
      }

      .card-body-sec {
        margin-top: 0;
      }
      .vertical-tab-inner {
        .tab-section {
          height: calc(100vh - 433px);
          @media only screen and (max-width: 1750px) {
            height: calc(100vh - 421px);
          }
          .tab-content {
            height: calc(100vh - 440px);
            @media only screen and (max-width: 1750px) {
              height: calc(100vh - 421px);
            }
          }
        }
      }
    }
  }
}

.contact-main-card-section {
  .card {
    padding-top: 0;

    @media only screen and (max-width: 1750px) {
      padding-top: 0;
    }
  }
}
.setting-tab-sec {
  .card {
    padding-top: 0;
    @media only screen and (max-width: 1750px) {
      padding-top: 8px;
    }
  }

  .tab-2-section {
    .main-inactive-grid {
      .tab-sub-section {
        .tab-sub-header {
          margin-left: 0;

          button {
            padding: 7px 15px;

            &.active {
              // padding-bottom: 12px;
              color: var(--themeColor);
            }
          }
        }

        .tab-sub-content {
          overflow: hidden;
          overflow-y: auto;
          height: calc(100vh - 337px);

          @media only screen and (max-width: 1750px) {
            height: calc(100vh - 327px);
          }

          .card {
            margin: 0px 5px 0;
            padding-top: 0px;
            max-height: calc(100vh - 360px);
          }
        }
      }
    }
  }
}
.customer-document-detail {
  .card {
    padding-top: 0px;

    @media only screen and (max-width: 1750px) {
      padding-top: 8px;
    }
  }
}
.document-section {
  .card {
    padding-top: 0px;

    @media only screen and (max-width: 1750px) {
      padding-top: 8px;
    }
  }
}

.notes-main-card-section {
  .card {
    padding-top: 7px;

    @media only screen and (max-width: 1750px) {
      padding-top: 7px;
    }
    .card-top-title-btn {
      padding-top: 0;
      @media only screen and (max-width: 1750px) {
        padding-top: 8px;
      }
    }
  }
}

.link-customer {
  .card {
    padding-top: 7px;
  }
}

.card-email-sec {
  .card {
    margin-bottom: 0;
    padding: 9px;
    box-shadow: 0px 0px 17px -10px var(--textColorLight);
  }
}

.badge-gradient-warning {
  background: var(--gradient-warning) !important;
  color: var(--whiteColor) !important;
}

.badge-gradient-success {
  background: var(--gradient-success) !important;
  color: var(--whiteColor) !important;
}

.badge-gradient-info {
  background: var(--gradient-info) !important;
  color: var(--whiteColor) !important;
}

.badge-gradient-danger {
  background: var(--gradient-danger) !important;
  color: var(--whiteColor) !important;
}

.badge-gradient-theme {
  background: var(--gradient-theme) !important;
  color: var(--whiteColor) !important;
}

.badge-gradient-Blocked {
  background: var(--blocked-theme) !important;
  color: var(--blocked-theme-btn) !important;
}

.badge-gradient-Frozen {
  background: var(--frozen-theme) !important;
  color: var(--frozen-theme-btn) !important;
}

.badge-gradient-Approved {
  background: var(--approved-theme) !important;
  color: var(--approved-theme-btn) !important;
}

.badge-gradient-Submitted {
  background: var(--submitted-theme) !important;
  color: var(--submitted-theme-btn) !important;
}

.badge-gradient-Pending {
  background: var(--pending-theme) !important;
  color: var(--pending-theme-btn) !important;
}

.badge-gradient-disabled {
  background: var(--disabled-theme) !important;
  color: var(--disabled-theme-btn) !important;
}

.badge-gradient-reject {
  background: var(--reject-color) !important;
  color: var(--reject-theme-btn) !important;
}

.status-btn {
  // color: var(--whiteColor);
  display: inline-block;
  // min-width: 95px;
  border-radius: 4px;
  // box-shadow:
  //   0 4px 8px rgba(126, 126, 126, 0.2),
  //   0 6px 20px rgba(116, 116, 116, 0.19);
  font-weight: 600;
  padding: 2px 10px;
  text-align: center;
  // align-self: flex-start;
  font-size: var(--commonFont);
}

.vertical-tab-card {
  .card {
    max-height: calc(100vh - 280px);

    @media only screen and (max-width: 1750px) {
      max-height: calc(100vh - 275px);
    }

    @media only screen and (max-width: 1366px) {
      max-height: calc(100vh - 240px);
    }

    .vertical-tab-inner {
      .card {
        height: auto;
      }
    }

    .card-body-sec {
      padding: 5px !important;
      margin-top: 0;

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 150px);
      }
    }
  }
}

.sidebar-model {
  .card {
    max-height: auto;
  }
}

.vertical-tab-card {
  &.organization {
    .card {
      max-height: calc(100vh - 110px);

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 100px);
      }

      .vertical-tab-inner {
        .card {
          max-height: auto;
        }
      }

      .card-body-sec {
        padding: 5px !important;
        margin-top: 0;
        max-height: calc(100vh - 100px);

        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 100px);
        }
      }
    }
  }
}

.setting-tab-sec {
  .card {
    max-height: calc(100vh - 265px);

    @media only screen and (max-width: 1750px) {
      max-height: calc(100vh - 275px);
    }

    .vertical-tab-inner {
      .card {
        max-height: auto;
      }
    }

    .card-body-sec {
      padding: 5px !important;
      margin-top: 0;

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 150px);
      }
    }
  }
}

.main-organiazation-history {
  .vertical-tab-inner {
    .tab-section {
      .tab-content {
        .card-body-sec {
          @media only screen and (max-width: 1750px) {
            max-height: auto !important;
          }
        }
      }
    }
  }
}

.main-customer-grid {
  .card-body-sec {
    margin-top: 0;
  }

  .tab-section {
    margin-bottom: 0;

    .tab-header {
      width: 100%;
      border-bottom: 1px solid var(--linkMenu);
      background: var(--whiteColor);
      box-shadow: 0px 5px 12px -7px #d8dde6;

      button {
        padding: 2px 25px 16px;
        border: none;
        background: var(--whiteColor);
        border-radius: 0px;
        font-weight: 600;
        font-size: var(--commonFont);
        border: none;
        border-right: 1px solid var(--whiteColor);
        margin-right: 0;
        color: var(--textColor);
        transition: 0.3s all;

        &.active {
          margin-bottom: 0;
          color: var(--themeColor);
          position: relative;
          padding-bottom: 16px;
          font-size: var(--commonFont);

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) rotate(180deg);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid var(--themeColor);
            /* Change color as needed */
          }

          &:hover {
            color: var(--themeColor);
          }
        }

        &:hover {
          color: var(--textColorLight);
        }
      }
    }

    .tab-content {
      padding-top: 0;

      .tab-body-section {
      }
    }

    &.vertical-tab {
      display: flex;
      align-items: flex-start;
      width: 100%;
      height: 100%;

      .tab-header {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 180px;
        height: 100%;
        border-right: 1px solid #d0d4df;
        border-bottom: 0;
        padding: 5px 0px 0 0px;

        // background: var(--bg-main-content);
        button {
          margin: 0;
          width: 100%;
          border-radius: 0;
          text-align: left;
          margin-bottom: 5px;
          border: none;
          border-bottom: 1px solid #d0d4df;
          margin-right: 10px;
          z-index: 1;
          background: #e2e6f0;
          border-right-color: var(--whiteColor);
          color: var(--textColor);

          &.active {
            background: var(--gradient-theme);
            border-right: 1px solid var(--themeColor);
            color: var(--whiteColor);
            border-left: 4px solid var(--textColor);
          }
        }
      }

      .tab-content {
        width: calc(100% - 180px);
      }

      .card {
        padding-right: 0;
        padding-top: 10px;
      }

      .card-body-sec {
        margin-top: 10px;
      }
    }
  }

  &.setting-tab-sec {
    .card-body-sec {
      margin-top: 0;

      @media only screen and (max-width: 1750px) {
        max-height: unset;
        height: auto;
      }
    }
  }
}

.main-customer-grid {
  .main-inactive-grid {
    .card-body-sec {
      margin-top: 0;
    }

    .tab-sub-section {
      margin-bottom: 30px;

      .tab-sub-header {
        border-bottom: 1px solid var(--linkMenu);
        background: var(--whiteColor);
        // margin-left: 20px;

        button {
          padding: 7px 25px;
          border: none;
          background: var(--whiteColor);
          border-radius: 0px;
          font-weight: 600;
          font-size: var(--commonFont);
          border: none;
          border-right: 1px solid var(--whiteColor);
          margin-right: 0;
          color: var(--textColor);
          transition: 0.5s all;
          border-bottom: 2px solid var(--whiteColor);

          &.active {
            margin-bottom: 0;
            color: var(--themeColor);
            position: relative;
            border-bottom: 2px solid var(--themeColor);
          }

          &:hover {
            background: var(--themeColorLight);
          }
        }
      }

      .tab-sub-content {
        padding-top: 0;

        .tab-sub-body-section {
          margin-right: 10px;
        }
      }
    }
  }
}

.setting-tab-sec {
  .card {
    .tab-section {
      .tab-sub-content {
        max-height: calc(100vh - 310px);

        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 245px);
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--inputBorder);
}

::-webkit-scrollbar-thumb {
  background: var(--gradient-theme);
}

.border-right-0 {
  .css-13cymwt-control {
    border-right: 1px solid transparent !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    height: 31px !important;

    .css-1hb7zxy-IndicatorsContainer {
      margin: auto;
    }
  }

  .css-12zf3ro-control,
  .css-t3ipsp-control {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-right: 1px solid var(--themeColor) !important;

    @media only screen and (max-width: 1750px) {
      height: 31px !important;
    }
  }
}

.horizontal-form {
  .border-left-r-0 {
    .validation-text {
      margin-left: 5px !important;
    }
  }
}

.border-left-r-0 {
  .input-label-part {
    input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      margin-top: 5px;

      @media only screen and (max-width: 1750px) {
        margin-top: 2px;
      }
    }
  }
}

.border-left-r-0-contactform {
  .input-label-part {
    input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      margin-top: 7px;
      height: 37px;

      @media only screen and (max-width: 1750px) {
        margin-top: 4px;
        height: 31px !important;
      }
    }
  }
}

.extension {
  input {
    margin-top: 7px;

    @media only screen and (max-width: 1750px) {
      margin-top: 5px;
    }
  }

  @media only screen and (max-width: 1200px) {
    margin-left: 0.75px;
  }
}

// Customer Docuement Detail

.supplier-document-detail {
  .card {
    padding-top: 3px;
    .card-body-sec {
      margin-top: 0;
      padding: 5px !important;
      height: calc(100vh - 303px);
      overflow: hidden;
      @media only screen and (max-width: 1750px) {
        height: calc(100vh - 297px);
      }
    }
  }
}

.customer-document-detail {
  .card {
    .card-body-sec {
      margin-top: 0;
      padding: 5px !important;
      height: calc(100vh - 300px);
      overflow: hidden;

      @media only screen and (max-width: 1750px) {
        height: calc(100vh - 303px);
      }
    }
  }
}

// Docuement Section

.docuement-section {
  .card {
    .card-body-sec {
      height: fit-content;
    }
  }
}

//Supplier Docuement Detail

.supplier-document-detail {
  .card {
    .card-body-sec {
      margin-top: 0;
      padding: 5px !important;
    }
  }
}

// Address Card Start
.address-card-section {
  .accordion-item {
    border: 1px solid #eaf1f9;
    border-radius: 4px !important;
    margin-bottom: 15px;

    .header-title-btn {
      .accordion-header {
        button {
          background: var(--linkMenu);
          padding: 7px 10px;
          color: var(--textColor);
          font-size: var(--commonFont);
          font-weight: 600;
          outline: none;
          box-shadow: none;

          &:not(.collapsed) {
            background: var(--themeColorLight);
            color: var(--themeColor);
          }
        }
      }
    }
  }

  .add-desc-part {
    padding-bottom: 0;

    .address-card-list {
      display: flex;
      flex-wrap: wrap;

      .address-main-card-section {
        width: 24%;
        margin: 0 0.5%;
        height: auto;
        margin-bottom: 16px;

        .address-card.address-archive {
          border: 1px solid #dc35455e;
        }

        .address-card {
          border: 1px solid var(--cardborderColor);
          padding: 5px;
          border-radius: 5px;
          margin-top: 2px;
          position: relative;
          min-height: 95px;
          height: 100%;

          .add-line {
            width: 82%;
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 1750px) {
              width: 75%;
            }

            span {
              color: #2d3641db;
              font-size: var(--commonFont-medium);

              &:first-child {
                font-weight: 600;
                text-transform: capitalize;
              }
            }
          }

          .edit-delete-button {
            position: absolute;
            top: 10px;
            right: 5px;

            .edit-btn {
              border: none;
              outline: none;
              box-shadow: none;
              background: transparent;
              padding: 0;

              img {
                width: 20px;
                transition: 0.5s all;

                @media only screen and (max-width: 1750px) {
                  width: 15px;
                }
              }

              &:hover {
                img {
                  filter: brightness(0);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Address Card end
.contact-card-section {
  .contact-type-title {
    background: var(--cardTitleBg);
    padding: 5px 10px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    p {
      font-weight: 600;
      color: var(--textColor);
      font-size: var(--commonFont);
      margin: 0;
    }
  }

  .add-desc-part {
    padding: 0 5px;
    margin-top: 10px !important;
  }
}

// Contact Card Start
.contact-card-list {
  display: flex;
  flex-wrap: wrap;

  .contact-main-card-section {
    width: 24%;
    margin: 0 0.5%;
    height: auto;
    margin-bottom: 16px;

    .contact-card {
      // box-shadow: 0px 1px 11px -6px var(--textColorLight);
      border: 1px solid var(--cardborderColor);
      padding: 5px;
      border-radius: 5px;

      margin-top: 2px;
      position: relative;
      height: 100%;
      min-height: 70px;

      // min-height: 125px;
      &.dropdown-open {
        background-color: #f1f4fc;
      }

      .add-line {
        display: flex;
        flex-direction: column;

        .user-icon-name {
          display: flex;
          align-items: flex-start;
          margin-bottom: 0;

          .user-icon {
            width: 15px;
            height: 15px;
          }

          .primary-label {
            color: var(--PrimaryColor);
            margin-left: 5px;
            font-size: var(--commonFont-extraSmall);
          }

          .contact-name {
            padding-right: 5px;
          }
        }

        .contact-right-info {
          margin-left: 0;
          display: flex;
          align-items: center;

          .email-sec {
          }

          .contact-sec {
          }
        }

        .contact-type {
          margin: 0 2px;
        }

        span {
          font-size: var(--commonFont-small);
          color: #2d3641db;

          &.user-name {
            font-size: var(--commonFont-small);
            color: var(--themeColor);
            margin-bottom: 0;
            // margin-left: 31px;
            // margin-top: -4px;
          }

          b {
            display: inline-block;
            min-width: 0px;
            font-weight: 600;
            letter-spacing: 0.3px;
          }
        }
      }

      .edit-delete-button {
        position: absolute;
        top: 5px;
        right: 5px;

        .edit-btn {
          border: none;
          outline: none;
          box-shadow: none;
          background: transparent;
          padding: 0;

          img {
            width: 15px;
            transition: 0.5s all;
          }

          &:hover {
            img {
              filter: brightness(0);
            }
          }
        }
      }

      //
      .contact-card-desc {
        position: relative;

        .contact-info {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #ebebf1;
          padding-bottom: 5px;

          .user-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--whiteColor);
            min-height: 26px;
            min-width: 26px;
            max-height: 26px;
            max-width: 26px;
            border-radius: 100%;
            // box-shadow: 0px 0px 15px -6px var(--themeColor);
            border: 1px solid #ebebf1;

            i {
              font-size: var(--commonFont-large);
              color: var(--themeColor);
            }

            @media only screen and (max-width: 1750px) {
            }
          }

          .contact-name {
            display: flex;
            align-items: center;
            margin-left: 5px;

            .contact-title {
              color: var(--themeColor);
              font-size: var(--commonFont);
              text-transform: capitalize;

              @media only screen and (max-width: 1750px) {
                font-size: var(--commonFont-small);
              }
            }

            .primary-label {
              font-size: var(--commonFont-extraSmall);
              color: var(--PrimaryColor);
              margin-left: 3px;
              font-weight: 600;
            }
          }
        }

        .contact-details {
          margin-top: 2px;

          .dropdown-sec {
            display: flex;
            align-items: center;
            // padding-left: 22px;
            margin-bottom: 3px;

            &:last-child {
              margin-bottom: 0;
            }

            .dropdown-desc-sec {
              display: flex;
              align-items: center;

              .icon-part {
                margin-right: 5px;

                i {
                  font-size: var(--commonFont);
                  opacity: 0.4;
                  color: #5e6470;
                }
              }

              .desc-part {
                display: flex;
                align-items: center;

                .values {
                  font-size: var(--commonFont-small);
                  color: #5e6470;

                  @media only screen and (max-width: 1750px) {
                    font-size: 9px;
                  }

                  &.primary-email {
                    // color: var(--themeColor);
                    color: #5e6470;
                  }
                }
              }
            }

            .drop-down {
              margin-left: 7px;
              cursor: pointer;

              i {
                &.fa-caret-down {
                  color: var(--themeColor);
                }
              }

              &.mobilenumber {
                margin-left: 10px;
              }

              .rotated {
                transform: rotate(180deg);
                transition: transform 0.3s ease;
              }
            }

            .contact-sec {
              display: flex;
              align-items: center;

              .number-list {
                display: flex;
                align-items: center;

                .number-type {
                  span {
                    min-width: 15px;
                    min-height: 15px;
                    max-width: 15px;
                    max-height: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: -1px;

                    i {
                      font-size: var(--commonFont);
                      opacity: 0.4;
                      color: #5e6470;

                      &.fa-mobile {
                        font-size: var(--commonFont-extraLarge);
                      }
                    }
                  }
                }

                .card-value {
                  font-size: var(--commonFont-small);
                  padding-left: 3px;
                  color: #5e6470;

                  @media only screen and (max-width: 1750px) {
                    font-size: 9px;
                  }

                  &.primary-phone {
                    color: var(--themeColor);
                    color: #5e6470;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.primary-card {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 3px 5px;
  border-bottom-right-radius: 5px;
  font-size: var(--commonFont-small);
  background: var(--PrimaryColor);
  color: var(--whiteColor) !important;
}

// Contact card End

.contact-type-badge {
  position: absolute;
  padding: 0px 5px;
  top: -10px;
  background: var(--themeColorLight);
  color: var(--blackColor);
  right: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: var(--commonFont-extraSmall);
  font-weight: 600;

  @media only screen and (max-width: 1750px) {
    font-size: 8px;
  }

  &.contact-badge {
    color: var(--whiteColor);
  }

  &.badge-default {
    background: var(--themeColorLight);
  }

  &.badge-primary {
    background: var(--PrimaryColor);
  }

  &.badge-endUser {
    background: var(--pending-theme-btn);
  }

  &.badge-purchasing {
    background: var(--submitted-theme-btn);
  }

  &.badge-submission {
    background: var(--submission-color-btn);
  }

  &.badge-followup {
    background: var(--frozen-theme-btn);
  }

  &.badge-ap {
    background: var(--disabled-theme-btn);
  }
}

.sidebar-contact-model {
  .sidebar-model {
    .side-model-section {
      .model-content {
        .model-body {
          height: calc(100vh - 52spx);
          overflow: hidden;
          overflow-y: auto;
        }
      }
    }
  }
}

.basic-left-part {
  .card {
    .edit-icon {
      min-width: unset;
      padding: 0;
      margin-left: 10px !important;

      img {
        filter: unset;
        width: 20px;
        margin: 0;
      }
    }
  }
}

.border-b {
  border-bottom: 2px dashed var(--inputBorder);
}

.document-list-sec {
  .document-listing {
    &.document-list-wrapper {
      max-height: 315px;
      min-height: 315px;
      overflow: hidden;
      overflow-y: auto;
      padding: 0 5px 0 0;
      @media only screen and (max-width: 1750px) {
        max-height: 250px;
        min-height: 250px;
      }
    }
    .documents {
      display: flex;
      align-items: center;
      padding: 10px;
      box-shadow: 0 3px 20px -10px var(--textColorLight);
      border-radius: 10px;
      border: 1px solid var(--linkMenu);
      margin: 15px 0px 10px 0px;
      &.document-list-item {
        box-shadow: 0 6px 24px 0 #0000000d, 0 0 0 1px #00000014 !important;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 !important;
        position: relative;
        .right-desc {
          .document-action {
            position: absolute;
            top: 20px;
            right: 10px;
          }
        }
      }

      @media only screen and (max-width: 1750px) {
        margin-top: 10px;
      }

      .left-icons {
        width: 70px;
        display: flex;
        align-items: center;
        &.mail-icon {
          background-color: #f3f6f8;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 40px;
          min-height: 40px;
          max-width: 40px;
          min-width: 40px;
          img {
            margin: auto;
            width: 50%;
          }
        }

        img {
          width: 40px;
        }
      }

      .right-desc {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        gap: 5px;

        .doc-details {
          padding-left: 5px;

          .document-name {
            font-weight: 600;
            color: var(--textColorLight);
            font-size: var(--commonFont-small);
          }

          .document-type {
            color: var(--textColorLight);
            font-size: var(--commonFont-extraSmall);
          }

          .document-typename {
            color: var(--themeColor);
            font-size: var(--commonFont);
            font-weight: 600;
          }
        }

        .document-action {
          display: flex;
          align-items: center;

          .action-icon {
            color: var(--themeColor);
            justify-content: flex-end;
            &.email-download-icon {
              background-color: #26bf94 !important;
              color: var(--whiteColor) !important;
              max-height: 25px !important;
              min-height: 25px !important;
              max-width: 25px !important;
              min-width: 25px !important;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
            }
            &.email-view-icon {
              background-color: var(--themeColor) !important;
              color: var(--whiteColor) !important;
              max-height: 25px !important;
              min-height: 25px !important;
              max-width: 25px !important;
              min-width: 25px !important;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
            }
          }

          span {
            &.action-icon {
              margin-right: 10px;
              cursor: pointer;

              img {
                width: 15px;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      &:hover {
        box-shadow: 0 3px 20px -10px var(--themeColor);
        border-color: var(--themeColorLight);
      }
    }
  }
}

.no-address {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: var(--commonFont-extraLarge);
  color: var(--textColor);
}

.document-section {
  .card-body-sec {
    margin-top: 0 !important;
    height: calc(100vh - 335px);

    @media only screen and (max-width: 1750px) {
      height: calc(100vh - 290px);
    }

    .document-list-sec {
      height: calc(100vh - 400px);
      overflow: hidden;
      overflow-y: auto;

      @media only screen and (max-width: 1750px) {
        height: calc(100vh - 365px);
        // height: calc(100vh - 295px);
      }
    }
  }

  .document-section {
    .card-body-sec {
      margin-top: 0 !important;
    }
  }

  @media only screen and (max-width: 1600px) {
    .input-label {
      font-size: var(--commonFont-small);
    }

    .checkbox-label-part {
      .input-label {
        font-size: var(--commonFont-small);
      }
    }

    .basic-customer-detail {
      .profile-info {
        h5 {
          font-size: var(--commonFont) !important;
        }

        .input-label-part {
          span {
            font-size: var(--commonFont-small);
          }
        }
      }

      .field-desc {
        font-size: var(--commonFont-medium);
      }
    }

    .horizontal-form {
      .input-label-part {
        .input-label-title {
          min-width: 120px;
          max-width: 120px;
        }
      }
    }
  }

  @media only screen and (max-width: 1366px) {
    .mb-2 {
      margin-bottom: 0 !important;
    }
  }

  @media only screen and (max-width: 1280px) {
    .css-1hb7zxy-IndicatorsContainer {
      .css-1xc3v61-indicatorContainer {
        padding: 0 !important;
      }
    }

    .css-qbdosj-Input {
      margin: 0;
    }
  }
}

.supplier-document {
  .document-section {
    .card-body-sec {
      height: calc(100vh - 315px);

      @media only screen and (max-width: 1750px) {
        height: calc(100vh - 280px);
      }

      .document-list-sec {
        height: calc(100vh - 315px);

        @media only screen and (max-width: 1750px) {
          height: calc(100vh - 280px);
        }
      }
    }
  }
}

@media only screen and (max-width: 1180px) {
  .tab-section {
    .tab-header {
      button {
        padding: 7px 10px 7px;
      }
    }
  }
}

.notes-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--card-shadow);
  margin: 10px 0;
  // background: #fec971;
  min-height: 100px;
  overflow: auto;
  height: 100%;

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    height: 100%;
  }

  .note-label {
    font-size: var(--commonFont-medium);
    color: #00000073;
    margin-bottom: 5px;
  }

  .bottom-info {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .note-date {
    font-size: var(--commonFont-small);
    color: var(--textColor);
  }

  .card-notes {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    // margin-top: -10px;

    .edit-button {
      display: flex;
      align-items: center;
      justify-content: center;

      .edit-btn {
        background: transparent;
        padding: 7px;

        min-width: 30px;
        background-color: var(--lightDarkblack);
        border-radius: 50%;
        transition: 0.5s all;

        img {
          width: 15px;
          margin: 0 auto;
          filter: brightness(0);
        }

        &:hover {
          background: var(--blackColor);

          img {
            filter: brightness(100);
          }
        }
      }
    }
  }
}

.card {
  .card-body-sec {
    .note-card-sec {
      // max-height: calc(100vh - 235px);
      height: calc(100vh - 357px);
      overflow: hidden;
      overflow-y: auto;

      @media only screen and (max-width: 1750px) {
        height: calc(100vh - 345px);
      }

      .bottom-info {
        .note-text {
          &.editor-section {
            display: flex;
            align-items: flex-start;
            width: 100%;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

.history-btn {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: relative;

  &:hover {
    .custom-tooltip {
      left: -12px;
      display: block;
      visibility: visible;
    }
  }
}

.option-icon {
  position: relative;

  &:hover {
    .custom-tooltip {
      left: -12px;
      display: block;
      visibility: visible;
    }
  }
}

.edit-btn {
  position: relative;

  &:hover {
    .custom-tooltip {
      left: -12px;
      display: block;
      visibility: visible;
    }
  }
}

.supplier-note {
  .card {
    .card-body-sec {
      .note-card-sec {
        height: calc(100vh - 357px);

        @media only screen and (max-width: 1750px) {
          height: calc(100vh - 344px);
        }
      }
    }
  }
}

.setting-tab-sec {
  .tab-sub-section {
    .tab-sub-content {
      .grid-section {
        margin: 10px 0;
      }
    }
  }

  .card {
    .card-body-sec {
      .note-card-sec {
        max-height: calc(100vh - 296px);
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }
}

.editor-section {
  p {
    font-size: var(--commonFont-small);
    color: var(--blackColor);
    margin-bottom: 5px;
    font-weight: 600;
  }

  a {
    color: var(--themeColor);
  }

  ol,
  ul {
    margin-top: 10px;

    li {
      margin-bottom: 5px;
      word-break: break-all;
    }
  }
}

.custom-height-tiny {
  .tiny-editor-sec {
    .tox-tinymce {
      height: 350px !important;
    }
  }
}

.randomColor {
  &.lightOrange {
    background: var(--lightOrange);
  }

  &.lightBlue {
    background: var(--lightBlue);
  }

  &.lightGreen {
    background: var(--lightGreen);
  }

  &.lightYellow {
    background: var(--lightYellow);
  }

  &.lightPink {
    background: var(--lightPink);
  }

  &.lightPurple {
    background: var(--lightPurple);
  }

  &.lightGray {
    background: var(--lightGray);
  }
}

.view-icon {
  cursor: pointer !important;
}

.view-reject-icon {
  cursor: auto;
}

.green-color {
  color: var(--PrimaryColor);
  font-size: var(--commonFont-2extraLarge);
  font-weight: lighter;
}

.red-color {
  color: var(--dangerColor);
  font-size: var(--commonFont-2extraLarge);
  font-weight: lighter;
}

.label-txt {
  b {
    min-width: 100px;
  }

  .fix-data {
    .card-value {
      font-size: var(--commonFont-small);
    }

    .primary-phone {
      color: var(--themeColor);
    }
  }
}

.copy-icon {
  img {
    width: 15px;
    height: 15px;
    margin-left: 7px;
    cursor: pointer !important;

    @media only screen and (max-width: 1750px) {
      width: 12px;
      height: 12px;
      margin-left: 5px;
    }
  }
}

.status-dropdown {
  width: auto;
  position: relative;
  padding-right: 0;

  .css-1dimb5e-singleValue {
    margin-left: 0 !important;
    font-size: var(--commonFont-small);
    font-weight: 600;
  }

  .css-13cymwt-control {
    cursor: pointer;
    background: transparent !important;
    border: none !important;

    .css-qbdosj-Input {
      margin: 0 !important;
    }
  }

  .css-1wy0on6,
  .css-1hb7zxy-IndicatorsContainer {
    visibility: hidden;
    height: 23px !important;
    min-height: 23px !important;
  }

  .css-t3ipsp-control {
    cursor: pointer;
    padding-top: 3px;
    // background: transparent !important;
    border: none !important;
    box-shadow: none !important;

    .css-qbdosj-Input {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  &.badge-gradient-Approved {
    background: transparent;

    .css-1dimb5e-singleValue {
      color: var(--approved-theme-btn);
      font-weight: 600;
    }
  }

  &.badge-gradient-Submitted {
    background: transparent;

    .css-1dimb5e-singleValue {
      color: var(--submitted-theme-btn);
      font-weight: 600;
    }
  }

  &.badge-gradient-Pending {
    background: transparent;

    .css-1dimb5e-singleValue {
      color: var(--pending-theme-btn);
      font-weight: 600;
    }
  }

  &.badge-gradient-Blocked {
    background: transparent;

    .css-1dimb5e-singleValue {
      color: var(--blocked-theme-btn);
      font-weight: 600;
    }
  }

  &.badge-gradient-Frozen {
    background: transparent;

    .css-1dimb5e-singleValue {
      color: var(--frozen-theme-btn);
      font-weight: 600;
    }
  }

  &.badge-gradient-info {
    background: transparent;

    .css-1dimb5e-singleValue {
      color: var(--themeColor);
      font-weight: 600;
    }
  }

  &.badge-gradient-disabled {
    background: transparent;

    .css-1dimb5e-singleValue {
      color: var(--disabled-theme-btn);
      font-weight: 600;
    }
  }

  &.badge-gradient-reject {
    background: transparent;

    .css-1dimb5e-singleValue {
      color: var(--reject-color-btn);
      font-weight: 600;
    }
  }

  .css-1nmdiq5-menu {
    .css-10wo9uf-option,
    .css-d7l1ni-option {
      color: var(--textColor);
    }
  }
}

input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}

input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}

input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background-color: var(--whiteColor);
  border: 1px solid var(--inputBorder);
}

input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background-color: var(--themeColor);
  border: 1px solid var(--themeColor);
}

input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid var(--whiteColor);
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
  position: absolute;
  top: 62%;
  left: 80%;
}

input[type="checkbox"]:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.add-setting-detail {
  .first-card {
    margin-bottom: 10px !important;
    border-color: var(--linkMenu);
  }
}

.inactive-scroll-bar {
  table {
    tbody {
      display: block;
      max-height: calc(100vh - 450px);
      overflow-y: auto;
      width: 100%;
      overflow-x: hidden;
    }
  }
}

.inactive-scroll-bar {
  table {
    tbody {
      display: block;
      max-height: calc(100vh - 450px);
      overflow-y: auto;
      width: 100%;
      overflow-x: hidden;
    }
  }
}

.customer-detail-tab-sec {
  .card {
    table {
      tbody {
        max-height: calc(100vh - 540px);

        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 500px);
        }
      }
    }
  }
}

.customer-list-all {
  margin-top: 5px !important;

  .card {
    padding: 10px 0 !important;

    table {
      tbody {
        max-height: calc(100vh - 335px);

        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 315px);
        }
      }
    }
  }

  table {
    thead {
      tr {
        th {
          &:nth-child(3) {
            display: flex;
            justify-content: left;
          }

          &:nth-child(4) {
            display: flex;
            justify-content: center;
          }

          &:last-child {
            justify-content: center;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:nth-child(3) {
            display: flex;
            justify-content: left;
          }

          &:nth-child(4) {
            display: flex;
            justify-content: center;
          }

          &:last-child {
            justify-content: center;
          }
        }
      }
    }
  }

  &.order-list-draft {
    .card {
      table {
        tbody {
          max-height: calc(100vh - 325px) !important;
          @media only screen and (max-width: 1750px) {
            max-height: calc(100vh - 305px) !important;
          }
        }
      }
    }
  }
  &.order-list-pending {
    .card {
      padding-top: 0 !important;
      table {
        tbody {
          max-height: calc(100vh - 335px) !important;
          @media only screen and (max-width: 1750px) {
            max-height: calc(100vh - 330px) !important;
          }
        }
      }
    }
  }
  &.order-list-pendingVerification {
    .card {
      table {
        tbody {
          max-height: calc(100vh - 328px) !important;
          @media only screen and (max-width: 1750px) {
            max-height: calc(100vh - 307px) !important;
          }
        }
      }
    }
  }
  &.order-list-waitingForCustomerApproval {
    .card {
      table {
        tbody {
          max-height: calc(100vh - 345px) !important;
          @media only screen and (max-width: 1750px) {
            max-height: calc(100vh - 320px) !important;
          }
        }
      }
    }
  }
  &.order-list-approved {
    .card {
      table {
        tbody {
          max-height: calc(100vh - 325px) !important;
          @media only screen and (max-width: 1750px) {
            max-height: calc(100vh - 305px) !important;
          }
        }
      }
    }
  }
}

.customer-list-all {
  .order-listing {
    .card {
      padding-top: 0 !important;
    }
  }
}
.sub-grid-list-customer {
  table {
    thead {
      tr {
        th {
          &:nth-child(4) {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

.w-20p {
  width: 20px;
}

.customer-list-submitted {
  table {
    thead {
      tr {
        th {
          &:nth-child(4) {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:nth-child(4) {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}

.status-desc {
  position: absolute;
  // left: 0;
  // top: 0;
  right: 0;
  bottom: 0;

  .field-info {
    padding: 3px 5px;
    border-bottom-right-radius: 5px;
    font-size: var(--commonFont-small);
    font-weight: 600;

    &.active-green-color {
      background: var(--PrimaryColor);
      color: var(--whiteColor) !important;
    }

    &.active-red-color {
      background: #dc3545;
      color: var(--whiteColor) !important;
    }
  }
}

// Checklist Section Start
.checklist-section {
  border-bottom: 1px solid var(--inputBorder);
  padding-bottom: 15px;
  padding-top: 15px;

  .sub-checklist {
    padding-left: 30px;

    .checkbox-part {
      .input-label-title {
        .input-label {
          font-weight: normal;
        }
      }
    }
  }
}

.cursor-disable {
  cursor: not-allowed !important;
}

.contact-icon-img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.primary-icon {
  img {
    filter: brightness(100);
  }
}

.primary-text-title {
  font-size: var(--commonFont-extraSmall) !important;
  color: var(--inputBorder) !important;
}

.btn-right-sec {
  display: flex;
  align-items: center;
}

.stepper-content {
  .tab-sub-content {
    margin-bottom: 0 !important;
  }
}

.input-list-button {
  table {
    tbody {
      tr {
        td {
          &:nth-child(1) {
            display: flex;
            justify-content: flex-start;
            word-break: break-all;
          }

          &:nth-child(2) {
            display: flex;
            justify-content: flex-start;
            word-break: break-all;
          }

          &:nth-child(3) {
            display: flex;
            justify-content: flex-start !important;
            word-break: break-all !important;
          }

          &:nth-child(4) {
            display: flex;
            justify-content: flex-start;
            word-break: break-all;
          }

          &:nth-child(5) {
            display: flex;
            justify-content: flex-start;
            word-break: break-all;
          }

          &:nth-child(6) {
            display: flex;
            justify-content: flex-start;
            word-break: break-all;
          }
        }
      }
    }
  }
}

.pop-up-input-btn {
  .card {
    padding: 12px !important;
    background: var(--whiteColor) !important;
    border-radius: 12px !important;
    margin-bottom: 20px !important;
    border: none !important;
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12) !important;
  }
}

.manage-customer-dropdown {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
}

.serach-bar-history {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;

  .name-field {
    min-width: 300px;
  }

  .user-name-field {
    min-width: 300px;
  }

  .date-field {
    min-width: 300px;
  }

  .refresh-btn-history {
    display: flex;
    justify-content: flex-start;
    min-width: auto;
  }
}

.custom-datepicker {
  .react-daterange-picker {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 34px;
    outline: 0 !important;
    position: relative;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
  }

  .react-daterange-picker__wrapper {
    border: none;
    padding-left: 4px;
  }

  .react-calendar__tile--active {
    background-color: var(--themeColor) !important;
  }

  .react-daterange-picker__inputGroup {
    min-width: 0;
    flex-grow: 0;
    font-size: var(--commonFont-small);
    cursor: pointer !important;
  }

  .react-daterange-picker__inputGroup__input:invalid {
    background: none !important;
  }

  .react-calendar__tile--now {
    background: var(--themeColor) !important;
    color: var(--whiteColor) !important;
  }

  .react-calendar {
    width: 280px;
    margin-top: 5px;
  }

  .react-daterange-picker__inputGroup__input {
    outline: none;
  }

  .react-daterange-picker__clear-button {
    margin-left: auto;

    i {
      color: hsl(0, 0%, 80%);

      &:hover {
        color: var(--themeColor) !important;
      }
    }
  }

  .react-daterange-picker__calendar-button__icon {
    stroke: hsl(0, 0%, 80%);

    &:hover {
      stroke: var(--themeColor) !important;
    }
  }
}

.first-word-break {
  table {
    tbody {
      tr {
        td:nth-child(1) {
          word-break: break-all;
        }
      }
    }
  }
}

.margin-top-checkbox {
  .input-field-sec {
    .checkbox-label-part {
      margin-top: 14px !important;

      @media only screen and (max-width: 1750px) {
        margin-top: 8px !important;
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  .status-dropdown {
    .css-t3ipsp-control,
    .css-13cymwt-control {
      padding: 0 !important;
    }
  }

  .address-model {
    .sidebar-model {
      .side-model-section {
        .model-content {
          &.content-40 {
            width: 40%;
          }
        }
      }
    }
  }

  .contact-card-section {
    .add-desc-part {
      .contact-card {
        .edit-delete-button {
          .edit-btn {
            img {
              width: 15px;
            }
          }
        }

        .add-line {
          span {
            b {
              text-transform: uppercase;
            }
          }

          .contact-icon-img {
            width: 13px;
            margin-right: 7px;
            padding-top: 2px;
          }
        }
      }
    }
  }

  .address-card-section {
    .add-desc-part {
      .address-card {
        .edit-delete-button {
          .edit-btn {
            img {
              width: 15px;
            }
          }
        }
      }
    }
  }

  input[type="checkbox"]:checked:before {
    // width: 17px;
    // height: 17px;
  }

  input[type="checkbox"]:checked:after {
    top: 66%;
    left: 75%;
  }

  .card {
    .card-top-title-btn {
      padding-bottom: 8px;
    }
  }

  .card-email-sec {
    .card {
      padding: 8px;
    }
  }

  .card-section-left.card-button-only {
    padding: 0 8px 8px;
  }

  .main-customer-grid {
    .tab-section {
      margin-bottom: 0;
    }

    &.setting-tab-sec {
      .tab-2-section {
        .main-inactive-grid {
          .tab-sub-section {
            .tab-sub-header button {
              &.active {
                padding-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }

  .setting-tab-sec {
    .tab-sub-section {
      .tab-sub-content {
        .grid-section {
          max-height: calc(100vh - 340px);
          margin: 10px 0;
        }
      }
    }
  }

  .main-customer-grid {
    .card {
      margin-bottom: 0;
    }

    .tab-section {
      .tab-header {
        button {
          padding: 2px 15px 10px;

          &.active {
            padding-bottom: 10px;
            padding: 2px 15px 10px;

            &:before {
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-top: 7px solid var(--themeColor);
            }
          }
        }
      }
    }

    @media only screen and (max-width: 1750px) {
      .main-inactive-grid {
        .card {
          margin-top: 0;
          padding: 0;
        }

        .tab-sub-header {
          margin-left: 10px !important;

          button {
            font-size: var(--commonFont-medium) !important;
            padding: 3px 15px 5px !important;
          }
        }
      }

      &.setting-tab-sec {
        .main-inactive-grid {
          .tab-sub-header {
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  .sidebar-contact-model {
    .no-address {
      .record-not-found {
        img {
          width: 75px;
        }
      }
    }

    .card-body-sec {
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .add-address-form {
    &.horizontal-form {
      .input-label-part {
        .input-label-title {
          min-width: 95px;
          max-width: 95px;
        }
      }

      @media only screen and (max-width: 1750px) {
        .validation-text {
          margin-left: 110px !important;
        }
      }
    }
  }

  .addEditContact-form {
    &.horizontal-form {
      .input-label-part {
        .input-label-title {
          min-width: 95px;
          max-width: 95px;
        }
      }
    }
  }

  .add-edit-emailAddressForm {
    &.horizontal-form {
      .input-label-part {
        .input-label-title {
          min-width: 95px;
          max-width: 95px;
        }
      }
    }
  }

  .add-edit-phoneForm {
    &.horizontal-form {
      .input-label-part {
        .input-label-title {
          min-width: 110px;
          max-width: 110px;
        }
      }
    }
  }

  .add-documentForm {
    &.horizontal-form {
      .input-label-part {
        .input-label-title {
          min-width: 110px;
          max-width: 110px;
        }
      }
    }
  }

  .add-edit-notesForm {
    &.horizontal-form {
      .input-label-part {
        .input-label-title {
          min-width: 90px;
          max-width: 90px;
        }
      }
    }
  }

  .input-label {
    // min-height: 28px;
  }

  .custom-datepicker {
    .react-daterange-picker {
      min-height: 28px !important;
    }
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--whiteColor);
  min-width: 200px;
  max-width: 205px;
  max-height: 170px;
  box-shadow: var(--card-shadow);
  z-index: 999;
  border-radius: 5px;
  margin-top: 5px;
  margin-left: 0px;
  overflow: hidden;
  overflow-y: auto;
  transform: translateX(-50%);
  left: 50%;
}

.dropdown-content {
  .contact-list {
    cursor: auto !important;
    color: var(--blackColor);
    padding: 7px;
    margin: 0;
    text-decoration: none;
    display: block;
    border-bottom: 1px solid rgb(240, 240, 240);
    justify-content: space-between;
    font-size: var(--commonFont-small);

    @media only screen and (max-width: 1750px) {
      font-size: 9px;
      padding: 5px 7px;
    }

    &:last-child {
      padding-bottom: 7px;
      border-bottom: 0;
    }
  }

  &.mobilenumber {
    min-width: 230px;
  }
}

.show {
  display: block;
}

.mb-input {
  margin-bottom: 15px;

  .input-label {
    min-height: unset;
  }

  // .validation-text {
  //   position: absolute;
  // }
}

.label-none {
  .input-label {
    visibility: hidden;
  }

  .input-label-part {
    input {
      margin-top: 0;
    }
  }
}

.basic-info-sec {
  .card {
    .card-body-sec {
      margin-top: 0;
    }
  }
}

.supplier-contacts {
  .sidebar-contact-model {
    .card-email-sec {
      .card {
        padding: 5px 10px;

        .card-body-sec {
          margin-top: 10px;
        }

        .card-body-sec {
          .note-card-sec {
          }
        }
      }
    }
  }
}

// Tab Section Start
.tab-section {
  margin-bottom: 30px;

  .tab-header {
    // border-bottom: 1px solid var(--linkMenu);

    // background: var(--whiteColor);
    @media only screen and (max-width: 1366px) {
      width: 86%;
    }

    button {
      padding: 7px 20px 7px;
      border: none;
      // background: var(--tabBgColor);
      background-color: transparent;
      border-radius: 5px !important;
      font-weight: 600;
      font-size: var(--commonFont-medium);
      border: none;
      // border-right: 1px solid var(--whiteColor);
      // margin-right: 5px;
      color: var(--textColor);
      margin: 0 5px;

      i {
        font-size: var(--commonFont);
        margin-right: 5px;
      }

      &:hover {
        background: var(--tab-btn-hover-bg);
        color: var(--tab-btn-hoverColor);
      }

      &.active {
        // margin-bottom: 10px;
        background: var(--themeColor);
        color: var(--whiteColor);
        // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
      }
    }
  }

  .main-customer-grid {
    .tab-section {
      .tab-section {
        margin-bottom: 30px;

        .tab-header {
          border-bottom: 1px solid var(--linkMenu);

          // background: var(--whiteColor);
          @media only screen and (max-width: 1366px) {
            width: 86%;
          }

          button {
            padding: 7px 25px 7px;
            border: none;
            background: var(--tabBgColor);
            border-radius: 0px;
            font-weight: 600;
            font-size: var(--commonFont-medium);
            border: none;
            border-right: 1px solid var(--whiteColor);
            margin-right: 0;
            color: var(--textColor);

            &.active {
              margin-bottom: 10px;
              background: var(--gradient-theme);
              color: var(--whiteColor);
            }
          }
        }

        .tab-content {
          // padding-top: 20px;

          .tab-body-section {
          }
        }

        &.vertical-tab {
          display: flex;
          align-items: flex-start;
          width: 100%;
          height: 100%;

          .tab-header {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 180px;
            height: 100%;
            border-right: 1px solid #d0d4df;
            border-bottom: 0;
            padding: 5px 0px 0 0px;

            // background: var(--bg-main-content);
            button {
              margin: 0;
              width: 100%;
              border-radius: 0;
              text-align: left;
              margin-bottom: 5px;
              border: none;
              border-bottom: 1px solid #d0d4df;
              margin-right: 10px;
              z-index: 1;
              background: #e2e6f0;
              border-right-color: var(--whiteColor);
              color: var(--textColor);

              &.active {
                background: var(--gradient-theme);
                border-right: 1px solid var(--themeColor);
                color: var(--whiteColor);
                border-left: 4px solid var(--textColor);
              }
            }
          }

          .tab-content {
            width: calc(100% - 180px);
          }

          .card {
            padding-right: 0;
            padding-top: 10px;
          }

          .card-body-sec {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .tab-content {
    // padding-top: 20px;

    .tab-body-section {
    }
  }

  &.vertical-tab {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    .tab-header {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 180px;
      height: 100%;
      border-right: 1px solid #d0d4df;
      border-bottom: 0;
      padding: 5px 0px 0 0px;

      // background: var(--bg-main-content);
      button {
        margin: 0;
        width: 100%;
        border-radius: 0;
        text-align: left;
        margin-bottom: 5px;
        border: none;
        border-bottom: 1px solid #d0d4df;
        margin-right: 10px;
        z-index: 1;
        background: #e2e6f0;
        border-right-color: var(--whiteColor);
        color: var(--textColor);

        &.active {
          background: var(--gradient-theme);
          border-right: 1px solid var(--themeColor);
          color: var(--whiteColor);
          border-left: 4px solid var(--textColor);
        }
      }
    }

    .tab-content {
      width: calc(100% - 180px);
    }

    .card {
      padding-right: 0;
      padding-top: 10px;
    }

    .card-body-sec {
      margin-top: 10px;
    }
  }
}

.vertical-tab-inner {
  .tab-section {
    display: flex;
    width: 100%;
    height: calc(100vh - 353px);
    position: relative;
    overflow: hidden;
    overflow-y: auto;

    @media only screen and (max-width: 1750px) {
      height: calc(100vh - 345px);
    }

    .tab-header {
      display: flex;
      flex-direction: column;
      border-right: 1px solid var(--linkMenu);
      border-bottom: 0 !important;
      width: 15%;
      overflow: hidden;
      transition: 0.5s all;
      padding-top: 11px;

      @media only screen and (max-width: 1024px) {
        width: 20%;
      }

      button {
        padding: 10px 15px;
        border: none;
        background: none;
        border-radius: 0px;
        font-weight: 600;
        font-size: var(--commonFont);
        border: none;
        border-bottom: 1px solid var(--linkMenu);
        margin-right: 0;
        color: var(--textColor);
        text-align: left;
        transition: 0.5s all;
        color: #5e6470;

        @media only screen and (max-width: 1024px) {
          padding: 7px 5px;
        }

        &:hover {
          background: var(--linkMenu);
        }

        &.active {
          background: var(--themeColorLight);
          color: var(--themeColor);
        }

        @media only screen and (max-width: 1750px) {
          padding: 10px 15px 8px;
        }
        @media only screen and (max-width: 1560px) {
          padding: 10px 10px 8px;
        }
      }
    }

    .tab-content {
      width: 85%;
      padding: 0 15px 0 15px;
      max-height: calc(100vh - 315px);
      overflow: hidden;
      overflow-y: auto;
      transition: 0.5s all;

      @media only screen and (max-width: 1750px) {
        max-height: calc(100vh - 280px);
        padding-bottom: 0;
      }

      @media only screen and (max-width: 1024px) {
        width: 80%;
      }
    }

    .collapse-tab {
      position: absolute;
      top: 0;
      left: calc(17% - 50px);
      min-height: 22px;
      min-width: 22px;
      max-height: 22px;
      max-width: 22px;
      background: var(--gradient-theme);
      border-radius: 100%;
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.5s all;
      z-index: 0;
      @media only screen and (max-width: 1560px) {
        left: calc(17% - 42px);
      }
      @media only screen and (max-width: 991px) {
        left: calc(23% - 45px);
      }

      img {
        filter: brightness(100);
        transform: rotate(90deg);
        transition: 0.5s all;
      }

      svg {
        filter: brightness(100);
        transform: rotate(90deg);
        transition: 0.5s all;
      }

      &:hover {
        box-shadow: var(--card-shadow);
        background: linear-gradient(to right, #2d3641db, #000000db);
      }
    }

    &.collapse-tabs {
      .collapse-tab {
        left: calc(0% - 0px);
        background: linear-gradient(to right, #2d3641db, #000000db);
        z-index: 999;

        img {
          transform: rotate(270deg);
        }

        svg {
          transform: rotate(270deg);
        }
      }

      .tab-header {
        width: 10px;
        padding-right: 5px;
        transition: 0.5s all;

        button {
          display: none;
        }
      }

      .tab-content {
        width: 100%;

        .contact-card-list {
          .contact-main-card-section {
            width: 19%;
          }
        }

        .address-card-list {
          .address-main-card-section {
            width: 19%;
          }
        }
      }
    }
  }

  .custom-pass-sec {
    position: relative;

    .password-hide-show {
      position: absolute;
      right: 15px;
      top: 49%;
      transform: translateY(-50%);

      img {
        width: 20px;
      }
    }
  }
}
.main-organiazation-history {
  .vertical-tab-inner {
    .tab-section {
      .tab-header {
        button {
          margin-left: 0;
          i {
            display: none;
          }
        }
      }
    }
  }
}
.supplier-contacts {
  .vertical-tab-inner {
    .tab-section {
      display: flex;
      width: 100%;
      height: calc(100vh - 360px);
      position: relative;
      overflow: hidden;
      overflow-y: auto;

      @media only screen and (max-width: 1750px) {
        height: calc(100vh - 305px);
      }

      .tab-header {
        display: flex;
        flex-direction: column;
        border-right: 1px solid var(--linkMenu);
        border-bottom: 0 !important;
        width: 15%;
        overflow: hidden;
        transition: 0.5s all;
        padding-top: 11px;

        @media only screen and (max-width: 1024px) {
          width: 20%;
        }

        button {
          padding: 10px 15px;
          border: none;
          background: none;
          border-radius: 0px;
          font-weight: 600;
          font-size: var(--commonFont);
          border: none;
          border-bottom: 1px solid var(--linkMenu);
          margin-right: 0;
          color: var(--textColor);
          text-align: left;
          transition: 0.5s all;
          color: #5e6470;

          @media only screen and (max-width: 1024px) {
            padding: 7px 5px;
          }

          &:hover {
            background: var(--linkMenu);
          }

          &.active {
            background: var(--themeColorLight);
            color: var(--themeColor);
          }

          @media only screen and (max-width: 1750px) {
            padding: 10px 15px 8px;
          }
        }
      }

      .tab-content {
        width: 85%;
        padding: 0 15px 0 15px;
        max-height: calc(100vh - 315px);
        overflow: hidden;
        overflow-y: auto;
        transition: 0.5s all;

        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 280px);
          padding-bottom: 0;
        }

        @media only screen and (max-width: 1024px) {
          width: 80%;
        }
      }

      .collapse-tab {
        position: absolute;
        top: 0;
        left: calc(17% - 42px);
        min-height: 22px;
        min-width: 22px;
        max-height: 22px;
        max-width: 22px;
        background: var(--gradient-theme);
        border-radius: 100%;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.5s all;
        z-index: 9;

        @media only screen and (max-width: 991px) {
          left: calc(23% - 45px);
        }

        img {
          filter: brightness(100);
          transform: rotate(90deg);
          transition: 0.5s all;
        }

        svg {
          filter: brightness(100);
          transform: rotate(90deg);
          transition: 0.5s all;
        }

        &:hover {
          box-shadow: var(--card-shadow);
          background: linear-gradient(to right, #2d3641db, #000000db);
        }
      }

      &.collapse-tabs {
        .collapse-tab {
          left: calc(0% - 0px);
          background: linear-gradient(to right, #2d3641db, #000000db);
          z-index: 999;

          img {
            transform: rotate(270deg);
          }

          svg {
            transform: rotate(270deg);
          }
        }

        .tab-header {
          width: 10px;
          padding-right: 5px;
          transition: 0.5s all;

          button {
            display: none;
          }
        }

        .tab-content {
          width: 100%;

          .contact-card-list {
            .contact-main-card-section {
              width: 19%;
            }
          }

          .address-card-list {
            .address-main-card-section {
              width: 19%;
            }
          }
        }
      }
    }

    .custom-pass-sec {
      position: relative;

      .password-hide-show {
        position: absolute;
        right: 15px;
        top: 49%;
        transform: translateY(-50%);

        img {
          width: 20px;
        }
      }
    }
  }
}

.final-input-forms {
  position: relative;

  .icon-fix {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);

    .input-icon {
      .component-iconify {
        width: 15px !important;
        height: 15px !important;
        color: var(--themeColor);
      }
    }
  }
}
.css-1u9des2-indicatorSeparator,
.css-15lsz6c-indicatorContainer {
  margin-top: 6px !important;
  margin-bottom: 3px !important;
}

.supplier-contacts {
  .vertical-tab-inner {
    .tab-section {
      height: calc(100vh - 360px);

      @media only screen and (max-width: 1750px) {
        height: calc(100vh - 351px);
      }
    }
  }
  &.custom-card-sec {
    .vertical-tab-inner {
      .tab-section {
        height: calc(100vh - 363px);

        @media only screen and (max-width: 1750px) {
          height: calc(100vh - 349px);
          .tab-content {
            height: calc(100vh - 350px);
          }
        }
      }
    }
  }
}

.order-body {
  max-height: calc(100vh - 138px);
  margin-right: 5px;

  .stepper-section {
    .stepper-content {
      max-height: calc(100vh - 180px) !important;
      min-height: calc(100vh - 180px) !important;
      padding: 0 !important;

      .upload-po-items {
        max-height: calc(100vh - 300px);
        overflow: hidden;
        overflow-y: auto;
        padding: 0 5px 0 0;
        @media only screen and (max-width: 1560px) {
          max-height: calc(100vh - 280px);
        }
      }
      .finance-contacts-tab {
        max-height: calc(100vh - 280px);
        overflow: hidden;
        overflow-y: auto;
        padding: 0 5px 0 0;
      }
    }
  }
}

// Tab Section End
.model-hight-fix {
  height: calc(100vh - 60px);

  canvas {
    width: 100% !important;
  }

  .document-container {
    width: auto !important;
    padding: 10px !important;
  }
}

.main-customer-grid {
  .tab-section {
    .tab-header {
      button {
        &.active {
          background: transparent;
        }
      }
    }
  }
}

.modal-body {
  padding: 12px !important;
}

.password-hide-show {
  position: absolute;
  width: 35px;
  right: 0px;
  top: 5px;

  img {
    width: 85%;
  }
}

.css-1p3m7a8-multiValue {
  background-color: var(--frozen-theme) !important;
  color: var(--frozen-theme-btn) !important;
}

.css-wsp0cs-MultiValueGeneric {
  color: var(--frozen-theme-btn) !important;
  font-weight: 500 !important;
}

.css-d7l1ni-option {
  background-color: #eae9eb !important;
  // color:var(--whiteColor) !important;
  border-radius: 5px;
  margin: 0px auto !important;
  // width: 97% !important;
}

.css-10wo9uf-option {
  margin: 0px 0px 0 10px !important;
  margin: 0px auto !important;
  // width: 97% !important;
}

.css-1nmdiq5-menu {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: 2px solid var(--cardborderColor);
  border-radius: 5px;
}

.css-tr4s17-option {
  margin: 0px auto !important;
  // width: 97% !important;
  background-color: var(--themeColor) !important;
  color: var(--whiteColor) !important;
  border-radius: 5px;
}

.css-1n6sfyn-MenuList {
  padding: 0 !important;

  .custom-option {
    .option-label {
      font-size: var(--commonFont-small);
    }
  }
}

.action-button {
  // cursor: pointer !important;
}
.vertical-tab-card.organization {
  .card {
    margin-bottom: 0;
    max-height: calc(100vh - 93px);
  }
  .main-organiazation-history {
    .vertical-tab-inner {
      .tab-section {
        display: flex;
        width: 100%;
        height: calc(100vh - 177px);
        position: relative;

        @media only screen and (max-width: 1750px) {
          height: calc(100vh - 167px);
        }
      }

      .tab-content {
        width: 85%;
        padding: 0 0 0px 15px;
        max-height: calc(100vh - 170px);
        overflow: hidden;
        overflow-y: auto;
        transition: 0.5s all;

        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 151px);
        }

        .organiazation-history .main-card {
          max-height: calc(100vh - 200px);

          @media only screen and (max-width: 1750px) {
            max-height: calc(100vh - 180px);
          }
        }
      }
    }
  }
}

.configuration-seprations {
  .separator::after {
    height: 30px;
  }
}

.configuration-Icon {
  .edit-icons {
    position: absolute;
    top: -9px;
    right: 0px;
    border-radius: 50%;
    color: var(--whiteColor) !important;
    background: var(--gradient-theme);
    width: 25px;
    height: 25px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}

.pagination-none {
  .table-pagination {
    display: none;
  }

  table {
    tbody {
      tr {
        &:last-child {
          // Remove border from the last row
          border-bottom: none !important;
        }
      }
    }
  }
}

.relative {
  position: relative !important;
}

.grid-over-flow {
  .vertical-tab-inner {
    .tab-section {
      height: calc(100vh - 185px) !important;

      .tab-content {
        max-height: calc(100vh - 185px) !important;
        height: calc(100vh - 185px) !important;
        padding: 0 0px 0 20px;
        @media only screen and (max-width: 1750px) {
          height: calc(100vh - 155px) !important;
        }
      }
    }
  }

  .api-provider {
    table {
      tbody {
        max-height: calc(100vh - 305px) !important;

        @media only screen and (max-width: 1750px) {
          max-height: calc(100vh - 255px) !important;
        }
      }
    }

    &.api-partner {
      table {
        tbody {
          max-height: calc(100vh - 365px) !important;

          @media only screen and (max-width: 1750px) {
            max-height: calc(100vh - 315px) !important;
          }
        }
      }
    }
  }
}

.last-center {
  table {
    tbody {
      tr {
        td:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.stepper-view {
  .vertical-tab-inner {
    .tab-section {
      max-height: calc(100vh - 330px);
    }
  }

  .document-section {
    .card-body-sec {
      height: calc(100vh - 348px);
    }
  }
}

@media only screen and (max-width: 1750px) {
  .stepper-view {
    .vertical-tab-inner {
      .tab-section {
        // max-height: calc(100vh - 200px);
      }
    }
  }
}

.table-email-sec {
  margin-bottom: 0;
  width: 100%;
  margin: 0 auto;
  // box-shadow: 0px 0px 17px -10px var(--textColorLight);

  .card {
    .card-top-title-btn {
      padding: 10px 0;
    }
  }
}

.disable {
  background: var(--disabled-theme);
  color: var(--disabled-theme-btn);
}

.disable-icon {
  font-size: var(--commonFont-large) !important;
  color: #9346f8 !important;
}

.addparameter-icon {
  font-size: var(--commonFont-2extraLarge) !important;
  color: #9346f8 !important;
}

.freeze-icon {
  font-size: var(--commonFont-large) !important;
  color: #0097bd !important;
}

.block-icon {
  font-size: var(--commonFont-large) !important;
  color: #d81f1f !important;
}

.reject-icon {
  font-size: var(--commonFont-large) !important;
  color: #ad0909 !important;
}

.un-lock-icon {
  font-size: var(--commonFont-large) !important;
  color: #0097bd !important;
}

.un-block-icon {
  font-size: var(--commonFont-large) !important;
  color: #d81f1f !important;
}

.view-icon {
  font-size: var(--commonFont-large) !important;
  color: #1755b3 !important;
}

.key-icon {
  font-size: var(--commonFont-large) !important;
  color: #4ca717 !important;
}

.api-icon {
  font-size: var(--commonFont-large) !important;
  color: #14538d !important;
}

.delete-icon {
  font-size: var(--commonFont-large) !important;
  color: #d10808 !important;
}

.order-price-list {
  .css-1jqq78o-placeholder {
    display: flex;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
  }

  table {
    overflow: unset;

    tbody {
      overflow: unset;
      max-height: unset;

      tr {
        td {
          padding: 2px 10px 2px 20px;

          @media only screen and (max-width: 1750px) {
            padding: 2px 10px;
          }

          span {
            padding: 4px 0;
          }
        }
      }
    }
  }
}

//Disable Dropdown Style

.dis-dropdown {
  .css-3iigni-container {
    background-color: #f2f2f2 !important;
    border-color: #cccccc !important;
    color: #888888 !important;
    opacity: 0.7 !important;

    .css-16xfy0z-control {
      min-height: 25px !important;
      height: 25px !important;
      margin-top: 0px;
      margin-bottom: 3px;
      cursor: no-drop !important;
      border: none !important;
      @media only screen and (max-width: 1750px) {
        min-height: 0px !important;
        height: 22px !important;
      }

      .css-1fdsijx-ValueContainer {
        height: 19px !important;

        .css-1jqq78o-placeholder {
          height: 21px;
        }

        .css-olqui2-singleValue {
          height: 30px;
          font-size: var(--commonFont-medium);
        }
      }

      .css-3w2yfm-ValueContainer {
        .css-1p3m7a8-multiValue {
          // max-height: 15px !important;
        }

        .css-wsp0cs-MultiValueGeneric {
          padding: 0 !important;
        }
      }

      .css-1hb7zxy-IndicatorsContainer {
        height: 10px !important;
      }
    }
  }
}

.react-select__menu {
  z-index: 9999;
}

.organization-tab-title {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--linkMenu);
  color: var(--themeColor);
  margin-bottom: 0;
  text-transform: capitalize;
  font-size: var(--commonFont-large);
  font-weight: 600;
  white-space: nowrap;
}

.main-organiazation-history {
  .card-body-sec {
    padding-bottom: 0;

    .mb-input {
      margin-bottom: 15px;
    }
  }
}

.assign-user-model-section {
  .right-btn {
    @media only screen and (max-width: 1750px) {
      margin-top: 20px !important;
    }

    @media only screen and (max-width: 1366px) {
      margin-top: 18px !important;
    }
  }
}

.detail-sec {
  width: calc(100% - 80px);
}

.customer-name {
  max-width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .info-icon {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 10px;

    .component-iconify {
      color: var(--themeColor);
      width: 18px !important;
      height: 18px !important;
    }

    .custom-tooltip {
      left: 100%;
      right: 0;
      top: -10px;
      writing-mode: unset;
      rotate: unset;
      z-index: 99;
      width: 300px;
      max-width: 300px;

      .tooltip-arrow {
        width: 0;
        height: 0;
        border-left: 6px solid #00000000;
        border-right: 6px solid #000000;
        border-bottom: 6px solid transparent;
        border-top: 6px solid transparent;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        opacity: 1;
        margin-left: -5px;
        margin-top: 13px;
        left: 0;
      }

      .tooltip-content {
        font-size: var(--commonFont);
        rotate: unset;
        transform: unset;
        max-width: 300px;
        min-width: 100px;
        overflow: hidden;
        white-space: normal;
        text-align: left;
        padding: 6px;
      }
    }

    &:hover {
      .custom-tooltip {
        display: block;
      }
    }
  }
}

@keyframes cd-bounce-1 {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    transform: translateX(20%) rotate(3deg);
  }

  45% {
    transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    transform: translateX(10%) rotate(2deg);
  }

  75% {
    transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}

.document-view {
  margin-top: 10px;
  max-height: calc(100vh - 150px);
  overflow: hidden;
  overflow-y: auto;

  @media only screen and (max-width: 1750px) {
    max-height: calc(100vh - 140px);
  }
}

.pg-viewer-wrapper {
  overflow-y: auto !important;

  .document-container {
    padding: 15px !important;
    width: 100% !important;
  }
}

.right-side-sec-padding-0 {
  .document-list-sec {
    .document-listing {
      .documents {
        .right-desc {
          padding-top: 0;
        }
      }
    }
  }
}

.sidebar-backIcon {
  width: 15px;
  height: 15px;
}

.menu-title {
  color: var(--sidebarActiveTextColor);
}

.organization-main-title {
  font-size: var(--commonFont-large);
  font-weight: 700;
  color: var(--themeColor);
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--linkMenu);

  .company-title {
    font-size: 12px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--textColor);
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 5px;
    background-color: #e7e7ff75;

    .company-image {
      // height:20px;
      width: 18px;
      margin-right: 5px;
    }

    span {
      line-height: normal;
      padding-top: 4px;
      padding-left: 3px;
    }
  }
}

.doc-view {
  .pdf-iframe {
    iframe {
      height: calc(100vh - 60px) !important;

      @media only screen and (max-width: 1750px) {
        height: calc(100vh - 57px) !important;
      }
    }
  }
}

.tooltip-div {
  position: relative;
  cursor: pointer;

  .tooltip-arrow-icon {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid black;
    position: absolute;
    top: -3px;
    left: -20%;
    transform: translateX(-50%);
    rotate: 180deg;
    opacity: 0;
    display: none;

    @media only screen and (max-width: 1750px) {
      top: -3px;
    }

    @media only screen and (max-width: 1650px) {
      top: -4px;
      left: -4px;
    }

    @media only screen and (max-width: 1366px) {
      top: -5px;
      left: -15%;
    }
  }

  .tooltip-show {
    display: none;
    padding: 5px 8px;
    border-radius: 4px;
    margin: 0;
    background-color: black;
    color: #fff !important;
    position: absolute;
    top: -26px;
    right: 0;
    font-size: var(--commonFont-extraSmall);
    pointer-events: none;
    width: max-content;
    z-index: 999;

    p {
      margin: 0;
    }
  }

  &:hover .tooltip-arrow-icon {
    opacity: 1;
    display: block;
  }

  &:hover .tooltip-show {
    display: block;
  }
}

.remove-line {
  hr {
    display: none;
  }
}

.validation-image-uploader {
  position: relative;

  .validation-text {
    bottom: -2px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.custom-file-upload-section {
  position: relative;

  .validation-text {
    margin-top: -10px !important;
  }

  &.customer-logo-sec {
    .custom-file-uploader {
      // height: 50px;
    }
  }
}

.add-order-doc-se {
  .custom-table {
    thead {
      tr {
        th {
          &:nth-child(1) {
            width: 10%;
          }

          &:nth-child(2) {
            width: 70%;
          }

          &:nth-child(3) {
            width: 20%;
          }
        }
      }
    }

    tbody {
    }

    tr {
      td {
        padding: 5px 10px;

        &:nth-child(1) {
          width: 10%;
        }

        &:nth-child(2) {
          width: 70%;
        }

        &:nth-child(3) {
          width: 20%;
        }
      }
    }
  }
}

// Email Template CK Editor Start
.email-template-editor {
  .ck-content {
    .table {
      table {
        overflow: inherit;
        border: inherit;
        height: max-content;
        width: fit-content;

        th {
        }

        td {
        }
      }
    }
  }
}

// Email Template CK Editor End

.width-65 {
  width: 65%;
}

.width-60 {
  width: 60%;
}

.left-profile-logo-sec {
  .image-upload-container {
    border: 2px dashed var(--inputBorder);
  }
}

.input-with-btn {
  display: flex;
  align-items: center;
  width: 100%;

  .final-input-forms {
    width: calc(100% - 40px);
    padding-right: 10px;
  }

  .btn-tooltip {
    width: 40px;

    .btn {
      min-width: auto;
      height: 34px;
      @media only screen and (max-width: 1750px) {
        height: 28px;
      }
    }
  }
}

.list-code-label {
  padding-left: 4px;
}

// .react-select__indicator-separator {
//   margin: unset !important;
//   padding: unset !important;
// }

.form-wrapper.po-upload {
  border-left: 3px solid var(--themeColor);
  padding-left: 5px;
  margin-bottom: 12px;
  font-size: 13px;
  color: #808080d6;
  font-weight: 700;
}

.confirmation-box.mismatch {
  box-shadow: rgb(220 53 69 / 13%) 0px 0px 0px 3px;

  .confirmation-title {
    background-color: #dc35452e;

    // &::after {
    //   content: "";
    //   background-color: #dc3545;
    // }
  }
}

.confirmation-box.confirmed {
  box-shadow: rgb(175 225 175 / 45%) 0px 0px 0px 3px;

  .confirmation-title {
    background-color: rgb(175 225 175 / 73%);

    // &::after {
    //   content: "";
    //   background-color: green;
    // }
  }
}

.confirmation-box {
  margin-top: 15px;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  border-radius: 5px;
  position: relative;

  .confirmation-title {
    padding: 8px 14px 8px 10px;
    border-bottom: 1px solid var(--cardborderColor);
    background-color: #f9f9f9;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: 600;

    .switch {
      margin-left: 10px;
    }

    // &::before {
    //   content: "";
    //   width: 16px;
    //   height: 16px;
    //   border-radius: 50%;
    //   border: 2px solid #ccc;
    //   background-color: var(--whiteColor);
    //   position: absolute;
    //   left: 8px;
    //   top: 10px;
    // }

    // &::after {
    //   content: "";
    //   width: 8px;
    //   height: 8px;
    //   border-radius: 50%;
    //   background-color: #1a73e8;
    //   position: absolute;
    //   left: 12px;
    //   top: 14px;
    // }
  }

  .confirmation-body {
    padding: 10px;

    .confirmation-btns {
      display: flex;
      gap: 10px;
      justify-content: flex-end;

      button {
        border-radius: 3px;
        padding: 3px 10px;
        font-size: var(--commonFont-small);
      }

      .reject-btn {
        background-color: transparent;
        outline: none;
        border: none;
        border: 1px solid red;
        color: red;
      }

      .accept-btn {
        background-color: transparent;
        outline: none;
        border: none;
        border: 1px solid green;
        background-color: green;
        color: var(--whiteColor);
      }
    }
  }
}

.accepted-box {
  margin-top: 15px;

  .address-box {
    border: 1px solid var(--themeColor);
    padding: 10px;
    border-radius: 5px;

    .add-line {
    }
  }
}

.badge-title {
  font-size: 13px;
  padding: 7px 10px;
  border-bottom: 1px solid #f2f2f2;
  color: #000000;
  margin-bottom: 0;
  justify-content: flex-start !important;
  display: flex;
}

.value-part {
  display: flex;
  gap: 5px;
  padding: 2px 2px;
  font-size: 13px !important;
  justify-content: flex-start;
  align-items: center;

  .index {
    display: flex;
    width: max-content !important;

    .component-iconify {
      width: 15px !important;
    }
  }

  &.pendingcustomer {
    color: #fe0808;

    .component-iconify {
      color: #fe0808 !important;
    }
  }

  &.paymenttermsnotmatching {
    color: #d84444;

    .component-iconify {
      color: #d84444 !important;
    }
  }

  &.ponumberiswrong {
    color: #003087;

    .component-iconify {
      color: #003087 !important;
    }
  }

  &.creditlimitcrossed {
    color: #ff9a00;

    .component-iconify {
      color: #ff9a00 !important;
    }
  }

  &.billingaddressmismatch {
    color: #1e87ca;

    .component-iconify {
      color: #1e87ca !important;
    }
  }

  &.invoiceemailmismatch {
    color: #9e027e;

    .component-iconify {
      color: #9e027e !important;
    }
  }

  &.incoterms {
    color: #80bc29;

    .component-iconify {
      color: #80bc29 !important;
    }
  }

  &.pastdue {
    color: #683bb5;

    .component-iconify {
      color: #683bb5 !important;
    }
  }

  &.replacement {
    color: #393939;

    .component-iconify {
      color: #393939 !important;
    }
  }

  &.leadtime {
    color: #de481e;

    .component-iconify {
      color: #de481e !important;
    }
  }

  &.stockverification {
    color: #ffc82b;

    .component-iconify {
      color: #ffc82b !important;
    }
  }

  &.pricemismatch {
    color: #00abe6;

    .component-iconify {
      color: #00abe6 !important;
    }
  }
}

.accordion-header {
  .info-user {
    .popup-left {
      right: 90% !important;
      position: absolute !important;
      left: unset !important;
    }
  }
}

.icon-container-hover-box {
  position: relative;
  display: inline-block;

  .component-iconify {
    color: var(--themeColor);
    cursor: pointer;
  }

  .hover-box-container {
    display: none;
    position: absolute;
    min-width: 250px;
    top: 100%;
    left: 0;
    z-index: 10;
    background-color: var(--whiteColor);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 4px;

    &.right {
      left: unset !important;
      right: 0;
    }
  }

  &:hover .hover-box-container {
    display: block;
  }
}

.date-picker {
  .react-datepicker__tab-loop {
    z-index: 9999;
    position: fixed;
  }
}

.date-picker {
  .react-datepicker__tab-loop {
    z-index: 9999;
    position: fixed;
  }
}

//Progress Bar
.order-progressbar {
  display: flex;
  align-items: center;
  gap: 8px;
}

.skillBarContainer {
  width: 100%;
  max-width: 350px;
  height: 8px;
  background: #e6eae3;
  overflow: hidden;
  text-align: center;
  border-radius: 100px;

  .skillBar {
    height: 8px;
    float: left;
    background: var(--themeColor);
    color: var(--whiteColor);
    font-size: var(--commonFont-medium);
    animation: slideIn 2s;
    display: flex;
    align-items: center;
    justify-content: center;

    @-webkit-keyframes slideIn {
      0% {
        width: 0;
      }

      25% {
        width: normal;
      }

      100% {
        width: normal; // Note: "normal" for `width` is invalid, please adjust if necessary
      }
    }

    @-moz-keyframes slideIn {
      0% {
        width: 0;
      }

      25% {
        width: normal;
      }

      100% {
        width: normal; // Same issue here
      }
    }

    @-o-keyframes slideIn {
      0% {
        width: 0;
      }

      25% {
        width: normal;
      }

      100% {
        width: normal; // Same issue here
      }
    }

    @keyframes slideIn {
      0% {
        width: 0;
      }

      25% {
        width: normal;
      }

      100% {
        width: normal; // Same issue here
      }
    }
  }
}

.skillBar-Value {
  white-space: nowrap;
  color: #000000;
  font-weight: 500;
}

.comments-sidebar {
  .side-model-section {
    .model-content {
      .model-body {
        padding: 0 !important;

        .body-center {
          height: 100% !important;
        }
      }
    }
  }
}

.order-list-badge {
  padding: 3px 10px !important;
  border-radius: 20px !important;
  font-size: var(--commonFont-extraSmall) !important;
  white-space: nowrap;
  display: inline-block;
  margin: 4px !important;

    &.red {
      color: #fe0808;
     background-color: #fe080830;
    }
  
    &.blue {
      color: #003087;
     background-color: #00308730;
    }
  
    &.darkRed {
      color: #d84444;
      background-color: #d8444430;
    }
  
    &.orange {
      color: #ff9a00;
      background-color: #ff9a0030;
    }
  
    &.lightblue {
      color: #1e87ca;
      background-color: #1e87ca30;
    }
  
    &.purple {
      color: #9e027e;
      background-color: #9e027e30;
    }
  
    &.green {
      color: #80bc29;
      background-color: #80bc2930;
    }
    &.violet {
      color: #683bb5;
      background-color: #683bb530;;
    }
    &.darkGray {
      color: #393939;
     background-color: #39393930;
    }
    &.brickRed {
      color: #de481e;
      background-color: #de481e30;
    }
    &.cyan {
      color: #00abe6;
      background-color: #00abe630;
    }
    &.yellow {
     color: #ffc82b;
     background-color: #ffc82b30;
    }
}

.price-details {
  .total-item-price {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid var(--cardborderColor);
    padding: 10px 0;

    .price-label {
      min-width: 215px;
      text-align: center;
    }

    .price-value {
      min-width: 318px;
      padding: 0 0 0 8px;
      font-size: var(--commonFont-medium);
      font-weight: 600;

      @media only screen and (max-width: 1750px) {
        min-width: 290px;
      }

      @media only screen and (max-width: 1560px) {
        min-width: 251px;
      }

      @media only screen and (max-width: 1366px) {
        min-width: 210px;
      }
    }
  }

  .other-charges-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-bottom: 1px solid var(--cardborderColor);
    padding: 10px 0;
    color: var(--themeColor);
    gap: 10px;

    .price-label {
    }

    .other-charges-object {
      display: flex;
      align-items: center;
      gap: 10px;

      .select-charge {
        min-width: 270px;

        @media only screen and (max-width: 1750px) {
          min-width: 240px;
        }

        @media only screen and (max-width: 1560px) {
          min-width: 210px;
        }

        @media only screen and (max-width: 1366px) {
          min-width: 180px;
        }
      }

      .card-charge-input {
        min-width: 270px;

        @media only screen and (max-width: 1750px) {
          min-width: 240px;
        }

        @media only screen and (max-width: 1560px) {
          min-width: 210px;
        }

        @media only screen and (max-width: 1366px) {
          min-width: 160px;
        }
      }
    }
  }
}

.upload-po-items {
  .card {
    margin-top: 7px;

    .section-title {
      padding-bottom: 5px;
    }

    .btn-right-sec {
      button {
        margin-top: 0 !important;
      }
    }
  }
}

.blank-space-btn-r {
  min-width: 35px;
}

.customer-all-details {
  .basic-customer-detail {
    .css-3iigni-container {
      margin-left: 5px !important;

      .css-16xfy0z-control {
        margin-bottom: 0 !important;
        min-height: 27px !important;
        height: 27px !important;

        .css-1dyz3mf {
          padding: 0 5px !important;
          min-height: 18px !important;
          height: 18px !important;

          .css-9jq23d {
            padding: 0 !important;
          }

          .css-1mkvw8y,
          .css-1p3m7a8-multiValue {
            margin: -2px 0 0 !important;
          }
        }

        .css-1wy0on6 {
          padding: 0 5px !important;
          max-height: 27px !important;
        }
      }
    }

    .css-1xc3v61-indicatorContainer {
      padding: 0 !important;
    }

    .css-13cymwt-control {
      height: 28px !important;
      padding-top: 1px !important;
    }
  }
}

.thirdparty-card-description {
  white-space: wrap;
}

.thirdparty-card-eventTitle {
  max-width: 450px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0px 5px;
  transition: all 0.5s ease;
}

.event-history-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--linkMenu);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.event-history-data {
  max-height: 550px;
  word-break: break-all;
  overflow: scroll;
}
.show-po-icon {
  margin-left: 6px;
  cursor: pointer;
  color: var(--themeColor);
  &.systemtask {
    display: flex;
    background-color: #685dd83d;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    .component-iconify {
      height: 15px !important;
      width: 15px !important;
    }
  }
}

.subscriber-event-list {
  .card {
    padding-top: 0;
    margin-bottom: 0;
  }
  .api-provider {
    table {
      tbody {
        max-height: calc(100vh - 357px) !important;
      }
    }
  }
}
.grid-over-flow {
  .event-fields-list {
    .card {
      padding-top: 0;
      margin-bottom: 0;
    }
    .api-provider {
      table {
        tbody {
          max-height: calc(100vh - 317px) !important;
        }
      }
    }
  }
}

.attachment-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  .attachment-row {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 10px;
  }
  .attachment-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--linkMenu);
    border-radius: 5px;
    padding: 4px 0px 4px 6px;
    .attachment-label {
      display: flex;
      align-items: center;
      width: calc(100% - 80px);
      // margin-right: 10px;
      min-width: 150px;
      img {
        width: 20px;
        margin-right: 10px;
      }
      .attachment-item-title {
        flex-grow: 1;
        font-size: var(--commonFont-medium);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 170px;
      }
    }
    .attachment-actions {
      display: flex;
      justify-content: center;
      gap: 10px;
      width: 80px;
      .btn-part {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        .component-iconify {
          width: 16px !important;
          height: 16px !important;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        &.pdf-view {
          background: #f0effc;
          color: var(--themeColor);
        }

        &.download-document {
          background-color: #e6ffee;
          color: #33cc33;
        }
      }
    }
  }
  .show-more-attachments {
    outline: none;
    border: none;
    background-color: var(--themeColorLight);
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
}

.grid-over-flow {
  .event-response-fields {
    .card {
      padding-top: 0;
      margin-bottom: 0;
    }
    .api-provider {
      table {
        tbody {
          max-height: calc(100vh - 317px) !important;
        }
      }
    }
  }
}

.grid-over-flow {
  .required-fields-map {
    .card {
      padding-top: 0;
      margin-bottom: 0;
    }
    .api-provider {
      table {
        tbody {
          max-height: calc(100vh - 317px) !important;
        }
      }
    }
  }
}
.grid-over-flow {
  .vertical-tab-inner {
    &.configuration-tabs {
      .tab-content {
        max-height: calc(100vh - 190px) !important;
        height: calc(100vh - 190px) !important;
      }
      .history-logs-list {
        .card {
          padding-top: 0;
          margin-bottom: 0;
        }
        .card-body-sec {
          .main-card {
            .vertical-timeline {
              max-height: calc(100vh - 286px);
              overflow: hidden;
              overflow-y: auto;
              @media only screen and (max-width: 1750px) {
                max-height: calc(100vh - 274px);
              }
            }
          }
        }
        .api-provider {
          table {
            tbody {
              max-height: calc(100vh - 317px) !important;
            }
          }
        }
      }
    }
  }
}
//document
.doc-view {
  .pdf-iframe {
    iframe {
      height: calc(100vh - 60px) !important;

      @media only screen and (max-width: 1750px) {
        height: calc(100vh - 57px) !important;
      }
    }

    img {
      width: 100% !important;
    }
    .ifream-doc {
      body {
        img {
          width: 100% !important;
        }
      }
    }
  }
}

/* Product Detail CenterModal over CenterMoadl */
// Target multiple backdrops
.modal-backdrop {
  &:nth-of-type(2) {
    z-index: 1045;
  }
}
.product-details-modal {
  z-index: 1046;
}

/* Product Detail CenterModal over CenterMoadl */

.row-gap {
  margin-top: -5px;
  --bs-gutter-x: 15px;
  --bs-gutter-y: 15px;
}
.row-gap-2 {
  --bs-gutter-x: 15px;
  --bs-gutter-y: 15px;
  row-gap: 0 !important;
}

.page-title {
  padding: 5px 10px;
  background-color: var(--themeColorLight);
  margin-bottom: 10px;
  margin-top: -7px;
  .form-wrapper {
    margin: 0;
  }
}
.toggle-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.toggle-label {
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
}

.mail-body-editor {
  .ck-content {
    height: 300px !important;
    @media screen and (max-width: 1750px) {
      height: 250px !important;
    }
    @media screen and (max-width: 1560px) {
      height: 200px !important;
    }
    @media screen and (max-width: 1366px) {
      height: 150px !important;
    }
  }
}

.mail-body-communication-editor {
  .ck-content {
    height: 500px !important;
    @media screen and (max-width: 1750px) {
      height: 400px !important;
    }
  }
}

.mail-file-uploader {
  max-height: 220px;
  overflow: hidden;
  overflow-y: auto;
}

.document-list-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
}

.hover-box-content {
  position: fixed;
  padding: 8px 10px;
  background-color: var(--whiteColor);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 6px;
  z-index: 1000;
  min-width: 250px;
  max-width: 250px;
  transition: opacity 0.2s ease, transform 0.2s ease;
  opacity: 1;

  &.hidden {
    opacity: 0;
    pointer-events: none;
    transform: scale(0.95);
  }

  // Animation for hover appearance
  &.visible {
    opacity: 1;
    transform: scale(1);
  }
}
