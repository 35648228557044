.sidebar-model {
  .side-model-section {
    height: 100vh;
    width: 100%;
    background: #00000059;
    position: fixed;
    top: -1px;
    right: -100%;
    opacity: 0;
    transition: 0.7s all;
    z-index: 99;

    &.scrollabel-background {
      pointer-events: none;
      background: #00000015;

      .model-body {
        border-left: 1px solid var(--themeColor);
        border-right: 1px solid var(--themeColor);
      }
    }

    .model-content {
      pointer-events: all;
      position: absolute;
      width: 50%;
      overflow: hidden;
      background: var(--whiteColor);
      height: 100vh;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);

      &.right-position {
        transition: all 0.5s ease-in-out;
        right: 0%;
        // left: 65%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &.left-position {
        left: 0;
        transition: all 0.5s ease-in-out;
        // right: 65%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &.content-95 {
        width: 95%;
      }

      &.content-90 {
        width: 90%;
      }

      &.content-85 {
        width: 85%;
      }

      &.content-80 {
        width: 80%;
      }

      &.content-75 {
        width: 75%;
      }

      &.content-70 {
        width: 70%;
      }

      &.content-65 {
        width: 65%;
      }

      &.content-60 {
        width: 60%;
      }

      &.content-55 {
        width: 55%;
      }

      &.content-50 {
        width: 50%;
      }

      &.content-45 {
        width: 45%;
      }

      &.content-40 {
        width: 40%;
      }

      &.content-35 {
        width: 35%;
      }

      &.content-30 {
        width: 30%;
      }

      &.content-25 {
        width: 25%;
      }

      &.content-20 {
        width: 20%;
      }

      .model-header {
        border-bottom: 1px solid rgba(206, 211, 216, 0.431372549);
        position: relative;
        background: var(--themeColor);
        display: flex;

        .model-title {
          font-size: var(--commonFont-large);
          font-weight: 600;
          padding: 10px;
          color: var(--whiteColor);
        }

        .close-btn {
          cursor: pointer;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);

          img {
            width: 30px;
            transition: 0.5s all;
            filter: brightness(100);

            @media only screen and (max-width: 1750px) {
              width: 21px;
            }

            svg {
              width: 30px;
              transition: 0.5s all;
              filter: brightness(100);

              @media only screen and (max-width: 1750px) {
                width: 21px;
              }
            }

            &:hover {
              img {
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
              }

              svg {
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
              }
            }
          }
        }
      }

      .model-body {
        padding: 0 12px;
        height: calc(100vh - 45px);
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }

  &.active-model {
    .side-model-section {
      right: 0;
      opacity: 1;
    }
  }
}

//Button toggle styling
.toggle-switch-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: 35px;

  .toggle-label {
    font-size: 16px;
    font-weight: 500;
    color: var(--whiteColor);
  }

  .toggle-switch {
    position: relative;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;

    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 3px;
      width: 16px;
      height: 16px;
      background-color: #fff;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }

    &.active {
      background-color: #2196f3;

      &::before {
        transform: translateX(18px);
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  .sidebar-contact-model {
    .sidebar-model {
      .side-model-section {
        .model-content {
          .model-header {
            .model-title {
              padding: 6px 10px;
            }

            .close-btn {
              img {
                width: 19px;
              }
            }
          }

          .model-body {
            height: calc(100vh - 44px);
            padding: 0px 13px 0;
          }
        }
      }
    }
  }
}

//responsive UI Design

@media only screen and (max-width: 1024px) {
  .sidebar-model {
    .side-model-section {
      .model-content.content-50 {
        width: 70%;
        // z-index: 99999 !important;
      }
    }
  }
}