.credit-limit-container {
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    overflow: hidden;
    margin-bottom: 12px;

    .comparison-header {
        display: flex;
        background: #f8fafc;
        border-bottom: 1px solid #e2e8f0;
        padding: 8px 16px;

        .header-item {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 13px;
            color: #64748b;
            font-weight: 600;

            i {
                font-size: 16px;
                color: #3b82f6;
            }
        }
    }

    .comparison-content {
        display: flex;
        padding: 12px 16px;
        gap: 16px;

        .limit-box {
            flex: 1;
            padding: 12px;
            border-radius: 4px;
            border: 1px solid #e2e8f0;
            background: #f8fafc;

            &.mismatch {
                border-color: #fecaca;
                background: #fef2f2;
            }

            .limit-details {
                .limit-value {
                    font-size: 13px;
                    font-weight: 500;
                    color: #1e293b;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }
            }
        }
    }
}

// Responsive styles
@media screen and (max-width: 768px) {
    .credit-limit-container {
        .comparison-header {
            padding: 6px 12px;

            .header-item {
                font-size: 12px;

                i {
                    font-size: 14px;
                }
            }
        }

        .comparison-content {
            flex-direction: column;
            padding: 8px 12px;
            gap: 8px;

            .limit-box {
                padding: 10px;

                .limit-details {
                    .limit-value {
                        font-size: 13px;
                    }
                }
            }
        }
    }
} 