.custom-badge {
  /* Container for the badge component, positioning it relative to its children */
  position: relative;
  display: inline-block;

  .badge {
    /* Style for the badge itself */
    position: absolute;
    color: var(--whiteColor); /* Text color for the badge, should be white */
    font-size: var(--commonFont-extraSmall); /* Font size of the badge content */
    font-weight: bold; /* Makes the badge content bold */
    padding: 4px 6px; /* Padding inside the badge for a rounded appearance */
    border-radius: 50px; /* Makes the badge a circular shape */
    display: flex;
    justify-content: center; /* Centering the content horizontally */
    align-items: center; /* Centering the content vertically */
    min-width: 5px;
    height: 15px;
    background-color: var(--themeColor);
    line-height: inherit; /* Fixed height to maintain a circular shape */

    /* Positioning classes for different badge locations */
    &.top-right {
      top: 0;
      right: 0;
      transform: translate(50%, -50%); /* Offsets the badge to be fully visible at top-right */
    }

    &.top-left {
      top: 0;
      left: 0;
      transform: translate(-50%, -50%); /* Offsets the badge to be fully visible at top-left */
    }

    &.bottom-right {
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%); /* Offsets the badge to be fully visible at bottom-right */
    }

    &.bottom-left {
      bottom: 0;
      left: 0;
      transform: translate(-50%, 50%); /* Offsets the badge to be fully visible at bottom-left */
    }
  }
}
