.mytask-section {
  overflow: hidden;

  .task-title {
    margin: 0 0 5px 0px;

    .search-section {
      .search-part {
        margin-right: 10px;
      }
    }
  }

  .btn-done {
    background-color: var(--themeColor);
    border-radius: 0px;
  }

  .btn-archive {
    background-color: var(--textColorLight);
    border-radius: 0px;
  }

  .tabs {
    flex-direction: column;
    display: flex;
  }

  .tab-button {
    display: flex;
    align-items: flex-start;
    border: none;
    background-color: var(--whiteColor);
    border-bottom: 1px solid #e8eae8;
    padding: 10px;
    text-align: start;
    color: #757575;

    &:focus-visible,
    &:focus {
      outline: none !important;
    }

    @media only screen and (max-width: 1366px) {
      padding: 5px;
    }

    &.active {
      background-color: #eeeef3;
    }

    .profile-icon {
      background-color: #d4d5fb;
      // padding: 8px;
      border-radius: 100%;
      color: #000 !important;
      font-weight: 500;
      font-size: var(--commonFont-medium) !important;
      margin: 0px 10px 0 0;
      min-width: 35px !important;
      min-height: 35px !important;
      max-width: 35px !important;
      max-height: 35px !important;
      line-height: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 1366px) {
        min-width: 22px !important;
        min-height: 22px !important;
        max-width: 22px !important;
        max-height: 22px !important;
        font-size: var(--commonFont-small) !important;
      }
    }

    .right-name-desc {
      width: calc(100% - 35px);
    }

    .title {
      display: flex;
      justify-content: space-between;
      font-size: var(--commonFont-small);
      color: var(--blackColor);

      .date {
        margin-left: 7px;
      }
    }
    .badge-fix{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .sub-title {
      font-size: var(--commonFont-small);
      color: #797979;
      margin-right: 10px;
    }
  }

  @media only screen and (max-width: 1366px) {
    .date {
      font-size: var(--commonFont-extraSmall);
    }
  }

  .task-tab {
    .tab-section-desc {
      .tab-section {
        .tab-content {
          .card {
            .card-body-sec {
              min-height: calc(100vh - 234px);
              max-height: calc(100vh - 234px);

              overflow: hidden;
              overflow-y: auto;
              margin-top: 0;

              @media only screen and (max-width: 1750px) {
                min-height: calc(100vh - 215px);
                max-height: calc(100vh - 215px);
              }
            }
          }
        }
      }

      .tab-header {
        padding-bottom: 0;
        margin-bottom: 5px;

        button {
          text-transform: uppercase;

          &:hover {
            background: unset;
            color: var(--textColorLight);
          }
        }
      }
    }
  }

  .card-section-left .card-button-only {
    padding: 8px;
  }

  .tab-section-desc {
    .tab-section {
      .tab-body-section {
        .card {
          padding: 12px 0;
          border-radius: 0;

          @media only screen and (max-width: 1750px) {
            padding: 8px 0;
          }

          .card-top-title-btn {
            padding-left: 12px;
            padding-right: 12px;
          }

          .module-listing {
            ul {
              margin: 0;
              padding: 0;
              list-style-type: none;

              li {
                padding: 0;
                cursor: pointer;

                .module-listing {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-bottom: 1px dashed #e8eae8;
                  color: var(--textColor);
                }
                .right-sec {
                  width: 80%;
                }
                .left-sec {
                  width: 20%;
                }
                span {
                  color: var(--textColor);
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 7px 10px;
                  text-transform: capitalize;
                  border-bottom: 1px dashed #e8eae8;
                }

                &.active {
                  span {
                    background: var(--themeColorLight);
                    color: var(--blackColor);
                  }
                }

                &:hover {
                  span {
                    background: var(--bg-main-content);
                    color: var(--blackColor);
                  }
                }

                span > .module-count {
                  padding: 5px;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 25px;
                  height: 25px;
                  background-color: var(--themeColor);
                  border-radius: 50%;
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .tab-section-desc {
    .main-customer-grid {
      .card {
        margin-bottom: 0;
      }
    }
  }

  .right-desc {
    .card {
      margin-bottom: 0;
    }
  }
}

.left-modual-sec {
  border-right: 1px solid #e8eae8;
}

.right-desc {
  .card-body-sec {
    // min-height: calc(100vh - 123px);
    // max-height: calc(100vh - 123px);
    // overflow: hidden;
    // overflow-y: auto;
    margin-top: 0;

    // @media only screen and (max-width: 1750px) {
    //   min-height: calc(100vh - 122px);
    //   max-height: calc(100vh - 122px);
    // }

    .task-detail {
      display: flex;
      flex-direction: column;
      position: relative;

      .task-head {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        margin-top: -5px;
        align-items: center;
        border-bottom: 1px solid #e8eae8;
        width: 100%;

        .date {
          text-align: end;
        }

        .view-customer {
          font-size: var(--commonFont-small);
          color: var(--themeColor);
          font-weight: 600;
          display: flex;
          align-items: center;
          cursor: pointer !important;
        }

        .view-order {
          font-size: var(--commonFont-small);
          color: var(--themeColor);
          font-weight: 600;
          display: flex;
          align-items: center;
          cursor: pointer !important;
        }
        .view-customer img {
          margin: 0 3px 0 0;
          vertical-align: middle;
        }

        .profile-icon {
          background-color: #d4d5fb;
          // padding: 8px;
          border-radius: 100%;
          color: #000 !important;
          font-weight: 500;
          font-size: var(--commonFont-large) !important;
          margin: 0px 10px;
          min-width: 32px !important;
          min-height: 32px !important;
          max-width: 32px !important;
          max-height: 32px !important;
          line-height: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          &.system-profileicon {
            border-radius: 5px !important;
            background-color: var(--themeColor) !important;
            color: var(--whiteColor) !important;
            line-height: normal;
          }
        }

        .title {
          display: flex;
          flex-direction: column;
          font-size: var(--commonFont-medium);

          h6 {
            margin-bottom: 0px;
            font-weight: 600;
          }

          .sub-title {
            font-size: var(--commonFont-medium);

            &.systemtask-title {
              font-size: var(--commonFont-small);
            }
          }
        }

        &.system-taskhead {
          border-bottom: none;
        }
      }

      .system-task {
        word-break: break-all;
        max-height: 320px;
        overflow: hidden;
        overflow-y: auto;
      }

      .task-footer {
        display: flex;
        gap: 10px;
        justify-content: flex-end;

        .reject-btn {
          background-color: #ff0000;
          color: var(--whiteColor);
        }

        .accept-btn {
          background-color: #58cb58;
          color: var(--whiteColor);
        }
      }

      .customer-information {
        padding: 15px 10px 0;
        width: 100%;

        .info-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          align-items: flex-start;

          .info-label {
            min-width: 96px;
            font-weight: 600;
            // color: #555;
            font-size: var(--commonFont-medium);

            &.label-similar {
              min-width: 70px;
            }
          }

          .info-list {
            list-style: none;
            padding-left: 10px;
          }

          .info-value {
            color: var(--themeColor);
            font-size: var(--commonFont-medium);
            font-weight: 600;

            &.info-value {
              font-weight: 600;
            }
          }
        }

        .info-row-field {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .info-label {
            // min-width: 70px;
            font-weight: 600;
            font-size: var(--commonFont-medium);

            @media only screen and (max-width: 1400px) {
              min-width: 46px;
            }
          }

          .info-value {
            color: var(--themeColor);
            font-size: var(--commonFont-medium);
            font-weight: 600;

            &.info-value {
              font-weight: 600;
            }
          }
        }

        &.systemtask-information {
          border-bottom: 1px solid #e8eae8;

          .max-height {
            max-height: 21px;
          }

          .info-row-field {
            .info-label {
              font-size: var(--commonFont) !important;
            }
          }

          .send-btn {
            .theme-button {
              padding: 5px 10px;
            }
          }
        }
      }

      &.second-section-part {
        .customer-information {
          padding-top: 0 !important;

          &.systemtask-information {
            padding-top: 10px !important;
            padding-bottom: 5px !important;
          }
        }
      }

      .value-comparison {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        width: 100%;

        .value-block {
          // width: 49%;
          // background: #f9f9f9;
          padding: 15px;
          display: flex;
          gap: 5px;
          border-radius: 8px;
          flex-direction: column;
          height: fit-content;
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }

          .value-title {
            font-size: var(--commonFont-large);
            // margin-bottom: 10px;
            color: #333;
            font-weight: 700;
          }

          .value-btn-card {
            position: relative;
          }

          .value-content {
            list-style: none;
            margin-bottom: 0;

            .value-label {
              font-weight: 600;
              font-size: var(--commonFont);
              text-transform: capitalize;
            }

            .value-data {
              color: var(--themeColor);
              font-weight: 600;
              font-size: var(--commonFont);
            }

            .dynamic-task-sepration {
              border-bottom: 3px dotted var(--tableBorderColor);
              width: 50%;
              padding-bottom: 3px;
              padding-top: 3px;
              // .value-label {
              //   &:last-child {
              //     margin-bottom: 5px;
              //   }
              // }
            }
          }

          .no-value {
            font-style: italic;

            &.system-task {
            }
          }
        }

        .document-list-sec {
          width: 100%;

          .document-listing {
            max-height: 600px;
            overflow: hidden;
            overflow-y: auto;

            .documents {
              .right-desc {
                padding-top: 0;
              }
            }
          }
        }
      }

      .detail-head {
        font-size: var(--commonFont);
        margin: 10px 0px;
        color: var(--themeColor);
        font-weight: 600;
      }

      .detail {
        border: 1px solid var(--themeColor);
        border-radius: 10px;
        margin: 0 20px 0 0px;
        padding: 8px;
        width: 98%;
      }

      .detail-btn {
        background: var(--themeColor);
        color: var(--whiteColor);
        padding: 0 10px;
        width: 170px;
        display: flex;
        height: 25px;
        align-items: center;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        cursor: pointer;
      }
    }

    .task-detail-sidebar {
      .task-content {
        height: calc(100vh - 183px);
        @media (max-width: 1560px) {
          height: calc(100vh - 176px);
        }
        
        position: relative;

        .systemtask-main-sec {
          min-height: 55%;
          max-height: 55%;
          overflow: hidden;
          overflow-y: auto;
        }

        .comments-section {
          min-height: 37%;
          max-height: 37%;
          // overflow: hidden;
          // overflow-y: auto;

          .comment-list {
            max-height: calc(100vh - 760px) !important;
            min-height: calc(100vh - 760px) !important;

            @media (max-width: 1560px) {
              max-height: calc(100vh - 680px) !important;
              min-height: calc(100vh - 680px) !important;
            }
  
          }
        }
      }
      .comments-section {
        // position: absolute;
        // width: 100%;
        // bott
        .comment-sections {
          .comment-main-card {
          }
        }
      }
    }
  }
}

.comment-sections {
  margin-top: 20px;

  .comment-main-card {
    // padding: 0 5px;
    height: 100%;

    .title-sec {
      margin: 5px 0;
      background: #f4f4f4;
      padding: 7px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .titles {
        font-weight: 600;
        margin-bottom: 0;
      }

      .comments-count {
        font-weight: 600;
        margin-bottom: 0;
        background: var(--lightDarkblack);
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--blackColor);
      }
    }

    .comment-list {
      max-height: calc(100vh - 470px);
      overflow: hidden;
      overflow-y: auto;

      .comment-sec-inner {
        position: relative;

        &:before {
          background-color: #0000;
          border: 1px dashed #0000001a;
          content: "";
          height: calc(100% - 20px);
          left: 25px;
          position: absolute;
          top: 25px;
          z-index: 0;
        }

        .profile-parts {
          display: flex;
          align-items: center;
          padding: 10px 10px 0;

          .profile-icons {
            z-index: 99;
            width: 30px;
            height: 30px;
            background: var(--themeColorLight);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            margin-right: 10px;
            font-weight: 600;
          }

          .name-time {
            .name-parts {
              color: var(--themeColor);
              margin-right: 20px;
              font-weight: 600;
            }

            .time-parts {
              font-size: var(--commonFont-small);
              color: var(--textColorLight);
            }
          }
        }

        .comment-parts {
          margin: 7px 50px 0;
          padding: 0 0 10px;
          border-bottom: 1px dashed #00000028;
          color: var(--blackColors);
        }

        &:last-child {
          .comment-parts {
            border-bottom: 0;
          }
        }
      }
    }

    .comments-input {
      margin-top: 20px;
      z-index: 99;
      position: absolute;
      width: 100%;
      bottom: 0;

      .input-btn-part {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .input-part {
          width: calc(100% - 50px);

          input {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          }
        }

        .btn-part {
          width: 50px;

          button {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            min-width: 50px;
          }
        }
      }
    }
  }
}

.bage-fix {
  display: flex;
  align-items: center;
}

.mytask-type-badge {
  margin-top: 5px;
  padding: 0px 5px;
  display: flex;
  background: var(--themeColorLight);
  color: var(--blackColor);
  border-radius: 3px;
  font-size: var(--commonFont-extraSmall);
  font-weight: 600;
}

.badge-accept {
  background: var(--approved-theme);
  color: var(--approved-theme-btn);
}

.badge-reject {
  background: var(--reject-color);
  color: var(--reject-theme-btn);
}

.badge-Open {
  background: var(--pending-theme);
  color: var(--pending-theme-btn);
}

.date {
  // text-align: end;
  font-size: var(--commonFont-small);
  white-space: nowrap;
  color: var(--textColorLight);
}

.list-style {
  list-style: none;
}

.filter-model-sec {
  .btn-right-sec {
    button {
      margin: 0;
      padding: 0;
      color: var(--themeColor);

      .component-iconify {
        width: 30px !important;
        height: 30px !important;

        svg {
          width: 30px !important;
          height: 30px !important;
        }
      }

      &.btn.undefined {
        display: none;
      }
    }

    .shorting-section {
      button {
        &.btn.undefined {
          display: flex;
          min-width: auto;
        }
      }
    }
  }
}

//My task Row Data

//My Task Detail Card
.value-btn {
  padding: 7px 10px;
  border-radius: 3px;
  text-align: center;
  min-width: 90px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;

  font-size: var(--commonFont);
  background-size: 180% 100%;
  transition: all 0.4s ease-in-out;
  font-weight: 700 !important;
  color: var(--whiteColor);
  background: var(--themeColor);
}

.customer-task-model {
  width: 400px;
  padding: 10px;
  background: var(--whiteColor);
  border-radius: 5px;
  border: 1px solid #f2f4f6;
  position: absolute;
  right: 0;
  top: calc(100% + 15px);
  z-index: 1111;
  box-shadow: 0px 0px 40px -9px #6a6a95;
  // max-height: 330px;
  // overflow: hidden;
  // overflow-y: auto;

  .detail-item {
    display: flex;
    justify-content: center;
  }

  .label-item {
    min-width: 150px;
    font-size: 12px;
    font-weight: 700;
  }

  .item-value {
    font-size: var(--commonFont);
    color: var(--textColor2);
    font-weight: 600;
    min-width: 250px;
  }

  .btn-item {
    display: flex;
    padding: 0;
    justify-content: flex-end;
  }
}

// Email template-style Start

// Address Start
.value-comparison-section {
  padding: 0 5px;
  margin: 10px 10px 0;

  .value-block {
    background: var(--whiteColor);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 16px -8px var(--themeColor);

    .main-title {
      padding: 0 5px 5px;
      border-bottom: 1px solid var(--themeColor);
      font-weight: 600;
      color: var(--themeColor);
      margin-bottom: 5px;
    }

    .sub-title {
      padding-left: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      span {
        &.main-title-type {
          text-transform: uppercase;
          font-size: var(--commonFont-small);
          color: var(--textColor2);
        }

        &.badge {
          background: var(--themeColorLight);
          color: var(--themeColor);
        }
      }
    }

    .section-desc {
      ul {
        list-style-type: none;
        padding: 0 0 0 10px;
        margin-bottom: 0;

        li {
          display: flex;
          align-items: center;

          span {
            font-weight: 600;

            &.true-false-value {
              color: var(--themeColor);
              font-weight: 600;

              span {
                color: var(--textColor);
              }
            }
          }

          @media only screen and (max-width: 1650px) {
            font-size: var(--commonFont-medium);
          }
        }
      }

      .table-sec {
        .email-table {
          thead {
            tr {
              th {
                &:first-child {
                  width: 80%;
                }

                &:last-child {
                  width: 20%;
                  text-align: center;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                &:first-child {
                  width: 80%;
                }

                &:last-child {
                  width: 20%;
                  text-align: center;
                  text-transform: capitalize;
                  color: var(--themeColor);
                  font-weight: 600;
                }
              }
            }
          }
        }
      }

      .phone-table {
        thead {
          tr {
            th {
              &:nth-child(1) {
                width: 26%;
              }

              &:nth-child(2) {
                width: 19%;
                text-align: center;
              }

              &:nth-child(3) {
                width: 19%;
                text-align: center;
              }

              &:nth-child(4) {
                width: 21%;
                text-align: center;
              }

              &:last-child {
                width: 15%;
                text-align: center;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              &:nth-child(1) {
                width: 26%;
              }

              &:nth-child(2) {
                width: 19%;
                text-align: center;
              }

              &:nth-child(3) {
                width: 19%;
                text-align: center;
              }

              &:nth-child(4) {
                width: 21%;
                text-align: center;
              }

              &:last-child {
                width: 15%;
                text-align: center;
                text-transform: capitalize;
                color: var(--themeColor);
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  &.delivery-method-sec {
    .value-block {
      .section-desc {
        ul {
          li {
            span {
              &:first-child {
                min-width: 130px;
              }
            }
          }
        }
      }
    }
  }

  &.career-method-sec {
    .value-block {
      .section-desc {
        ul {
          li {
            span {
              &:first-child {
                min-width: 120px;
              }
            }
          }
        }
      }
    }
  }

  &.address-info-sec {
    .value-block {
      .section-desc {
        ul {
          li {
            span {
              &:first-child {
                min-width: 200px;
              }
            }
          }
        }
      }
    }
  }
}

// Address End

// Email template-style End

//archive task
.centertext-count {
  .card-section-left.card-button-only {
    .card-top-title-btn {
      .manage-customer-dropdown {
        .center-title-text {
          h4 {
            font-size: var(--commonFont-medium) !important;
            margin: 0 !important;
          }
        }
      }
    }
  }
}

// manual design

.billing-address-box {
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
}

.order-po {
  display: flex;
  justify-content: start;
  align-items: center;
}
.badge-order-id {
  display: flex;
  justify-content: end;
  align-items: center;
}
