// Radio Start
.radio-buttons {
    display: flex;
    align-items: center;
    margin-left: 5px;
    .radio-label{
      word-break: normal;
      white-space: nowrap;
    }
  }
  .radio {
    label {
      display: flex;
      align-items: center;
      line-height: normal;
  
    }
  
    input {
      position: absolute;
      opacity: 0;
  
      +.radio-label {
        &:before {
          content: '';
          background: var(--whiteColor);
          border-radius: 100%;
          border: 1px solid var(--themeColor);
          display: inline-block;
          width: 20px;
          height: 20px;
          position: relative;
          top: 0;
          margin-right: 7px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
  
      &:checked {
        +.radio-label {
          &:before {
            background-color: var(--themeColor);
            box-shadow: inset 0 0 0 4px var(--whiteColor);
          }
        }
      }
  
      &:focus {
        +.radio-label {
          &:before {
            outline: none;
            border-color: var(--radioButtonBackgroundColor);
          }
        }
      }
  
      &:disabled {
        +.radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px var(--whiteColor);
            border-color: var(--whiteColor);
            background: var(--whiteColor);
          }
        }
      }
  
      +.radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
  
  // Radio End
  
  @media only screen and (max-width: 1560px) {
    .radio {
      input {
        position: absolute;
        opacity: 0;
  
        +.radio-label {
          &:before {
            vertical-align: middle;
            width: 12px;
            height: 12px;
            margin-right: 3px;
          }
        }
      }
    }
  }