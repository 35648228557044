.system-task-desc {
  .task-detail {
    .task-header {
      border-bottom: 1px solid #e2e8f0;
      margin-bottom: 8px;
      padding-bottom: 7px;
      display: flex;
      justify-content: space-between;

      .task-profile {
        display: flex;
        align-items: center;
        gap: 12px;

        .profile-avatar {
          width: 35px;
          height: 35px;
          background: #3b82f6;
          color: white;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 600;
        }

        .profile-info {
          display: flex;
          flex-direction: column;
          gap: 2px;
          padding: 0 0 5px 0;
          line-height: normal;
          .task-title {
            font-size: var(--commonFont-medium);
            color: #1e293b;
            font-weight: 500;
            overflow-wrap: anywhere;
            height: auto;
            margin: 0 !important;
            display: flex;
            align-items: center;
            gap: 5px;
            line-height: normal;
            &.task-title-system-task{
              font-size: var(--commonFont-large);
              font-weight: 600;
            }
            .task-subtitle {
              font-size: var(--commonFont-small);
              font-weight: 400;
              color: #64748b;
              margin: 0 !important;
            }
          }

          .task-meta {
            display: flex;
            gap: 5px;
            color: #64748b;
            font-size: var(--commonFont-small);

            .generated-by,
            .generated-date {
              strong {
                color: #1e293b;
              }
            }
          }
        }
      }

      .task-badge-status {
        .info-item {
          display: flex;
          flex-direction: column;
          gap: 2px;
          align-items: flex-end;

          .info-label {
            font-size: 11px;
            color: #64748b;
            font-weight: 500;
          }

          .info-value {
            font-size: 11px;
            color: #1e293b;
            font-weight: 500;
            overflow-wrap: anywhere;
            height: auto;
            justify-content: left !important;

            &.order-link {
              color: #3b82f6;
              cursor: pointer;
              text-decoration: underline;

              &:hover {
                color: #2563eb;
              }
            }
          }

          .status-badge {
            display: inline-flex;
            align-items: center;
            padding: 0px 8px;
            border-radius: 4px;
            font-size: var(--commonFont-small);
            font-weight: 500;
            width: fit-content;
            margin-top: 0px;

            i {
              font-size: 14px;
            }

            &.success {
              background-color: #dcfce7;
              color: #166534;
            }

            &.error {
              background-color: #fee2e2;
              color: #991b1b;
            }

            &.default {
              background-color: #f1f5f9;
              color: #475569;
            }
          }
        }
      }
    }

    .task-content {
      .refresh-btn {
        position: absolute;
        color: #000;
        top: 5px;
        right: 0;
      }

      .info-section {
        background: #f8fafc;
        border-radius: 8px;
        padding: 10px 20px;
        margin-bottom: 10px;

        .info-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 20px;
          row-gap: 15px;

          .info-item {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .info-label {
              font-size: 11px;
              color: #64748b;
              font-weight: 500;
            }

            .info-value {
              font-size: 11px;
              color: #1e293b;
              font-weight: 500;
              overflow-wrap: anywhere;
              height: auto;
              justify-content: left !important;

              &.order-link {
                color: #3b82f6;
                cursor: pointer;
                text-decoration: underline;

                &:hover {
                  color: #2563eb;
                }
              }
            }

            .status-badge {
              display: inline-flex;
              align-items: center;
              padding: 4px 8px;
              border-radius: 4px;
              font-size: 12px;
              font-weight: 500;
              width: fit-content;

              i {
                font-size: 14px;
              }

              &.success {
                background-color: #dcfce7;
                color: #166534;
              }

              &.error {
                background-color: #fee2e2;
                color: #991b1b;
              }

              &.default {
                background-color: #f1f5f9;
                color: #475569;
              }
            }
          }
        }
      }

      .badges-section {
        margin-bottom: 24px;
      }

      .task-actions {
        display: flex;
        gap: 12px;
        justify-content: flex-end;
        min-height: 37px;
        max-height: 37px;
        @media (max-width: 1560px) {
          min-height: 28px;
          max-height: 28px;
        }

        .action-btn {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px 16px;
          border-radius: 6px;
          font-weight: 500;
          transition: all 0.2s ease;

          i {
            font-size: 20px;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.accept-btn {
            background: #22c55e;
            color: white;
            border: none;

            &:hover {
              background: #16a34a;
            }
          }

          &.reject-btn {
            background: #ef4444;
            color: white;
            border: none;

            &:hover {
              background: #dc2626;
            }
          }
        }
      }

      .comments-section {
        position: relative;
      }
    }
  }
}

.status-badge {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 9px;
  margin-top: 2px;

  i {
    font-size: 14px;
  }

  &.success {
    background-color: #dcfce7;
    color: #166534;
  }

  &.error {
    background-color: #fee2e2;
    color: #991b1b;
  }

  &.default {
    background-color: #f1f5f9;
    color: #475569;
  }
}

// Responsive styles
@media screen and (max-width: 768px) {
  .system-task-desc {
    .task-detail {
      .task-header {
        padding: 16px;
        margin-bottom: 16px;

        .task-profile {
          .profile-avatar {
            width: 40px;
            height: 40px;
            font-size: 16px;
          }

          .profile-info {
            .task-title {
              font-size: 20px;
            }

            .task-meta {
              font-size: 12px;
            }
          }
        }
      }

      .task-content {
        padding: 0 16px;

        .info-section {
          padding: 16px;
          margin-bottom: 16px;

          .info-grid {
            grid-template-columns: 1fr;
            gap: 16px;
          }
        }

        .task-actions {
          flex-direction: column;
          gap: 8px;

          .action-btn {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
  }
}
