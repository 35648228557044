// Contact Card New UI Start
.custom-contact-card {
  display: flex;
  flex-wrap: wrap;

  .contact-main-card-section {
    width: 24%;
    margin: 0 0.5%;
    height: auto;
    margin-bottom: 16px;

    .card-section-body {
      padding: 10px 10px 35px;
      background: var(--bg-main-content);
      border-radius: 5px;
      border: 1px solid #e2e2ec;
      height: 100%;
      min-height: 124px;
      position: relative;

      @media only screen and (max-width: 1750px) {
        min-height: 112px;
      }

      .top-profile-section {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .profile-sec {
          display: flex;
          align-items: flex-start;

          .profile-icon {
            min-width: 25px !important;
            min-height: 25px !important;
            max-width: 25px !important;
            max-height: 25px !important;
            background: linear-gradient(to right,
                rgba(45, 54, 65, 0.8588235294),
                rgba(0, 0, 0, 0.8588235294));
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            color: var(--whiteColor);
            padding: 3px;
            margin: 0;
          }

          .contact-name {
            margin-left: 10px;
            font-weight: 600;
            text-transform: capitalize;
            word-break: break-all;
          }
        }

        .right-action-icon {
          img {
            width: 15px;
            height: 15px;
            object-fit: contain;
            transition: 0.5s all;
            filter: brightness(0);
          }

          &:hover {
            img {
              filter: unset;
            }
          }

          .edit-view-icon {
            display: flex;
            align-items: center;

            .edit-delete-button {
              margin-right: 10px;
              margin-top: -2px !important;

              .edit-btn {
                border: none;
                padding: 0;
                background: none;

                img {
                  width: 15px;
                  filter: brightness(0);
                  transition: 0.5s all;
                }
              }

              &:hover {
                .edit-btn {
                  img {
                    filter: unset;
                  }
                }
              }
            }
          }

          .customer-detail-model {
            min-width: 100%;
            padding: 10px;
            background: var(--whiteColor);
            border-radius: 5px;
            border: 1px solid #f2f4f6;
            position: absolute;
            right: -15px;
            top: 30px;
            z-index: 1;
            box-shadow: 0px 0px 40px -9px #6a6a95;
            height: 0;
            width: 0;
            overflow: hidden;
            visibility: hidden;

            &.open-model {
              height: auto;
              width: auto;
              visibility: visible;
            }

            .customer-card-top-sec {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              border-bottom: 1px solid #dce4ee;
              padding-bottom: 5px;

              .profile-icon {
                margin: 0;
                content: "";
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                background: linear-gradient(to right,
                    rgba(45, 54, 65, 0.8588235294),
                    rgba(0, 0, 0, 0.8588235294));

                min-width: 50px !important;
                min-height: 50px !important;
                max-width: 50px !important;
                max-height: 50px !important;

                .profile-text {
                  color: var(--whiteColor);
                  font-size: var(--commonFont-medium);

                }
              }

              .profile-name {
                font-size: var(--commonFont-large);
                font-weight: bold;
                margin-top: 5px;
                color: var(--textColor2);
              }
            }

            .bottom-contact-desc {
              margin-top: 10px;

              .contact-part {
                margin-bottom: 5px;

                &:last-child {
                  margin-bottom: 0;
                }

                .type-title {
                  display: flex;
                  align-items: center;

                  i {
                    font-size: var(--commonFont-large);
                    color: var(--textColor2);
                    margin-right: 5px;
                    width: 15px;
                    height: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.fa-mobile {
                      font-size: var(--commonFont-extraLarge);
                    }
                  }

                  span {
                    font-size: var(--commonFont);
                    color: var(--textColor2);
                    font-weight: 600;
                  }
                }

                .contact-type-list {
                  display: flex;
                  align-items: flex-start;
                  i{
                    padding-top: 3px;
                    color: var(--textColor2);
                  }
                  ul {
                    margin-top: -2px;
                    list-style-type: none;
                    padding-left: 7px;
                    margin-bottom: 0;

                    &.number-list {
                      padding-left: 0px;
                      margin-top: 2px;

                      li {
                        .card-value {
                          font-size: var(--commonFont-small);
                        }

                        .contact-list {
                          .number-list {
                            span {
                              &:nth-child(2) {
                                font-size: var(--commonFont-small);
                              }
                            }
                          }
                        }
                      }
                    }

                    li {
                      margin-bottom: 0;
                      font-size: var(--commonFont-medium);

                      .values {}

                      .card-value {
                        &.primary-phone {
                          position: relative;
                          display: flex;

                          &::after {
                            content: "\f00c";
                            font-family: FontAwesome;
                            font-style: normal;
                            font-weight: normal;
                            text-decoration: inherit;
                            margin-left: 5px;
                            padding: 3px;
                            border-radius: 100%;
                            color: #fff;
                            background-color: var(--PrimaryColorBright);
                            font-size: 9px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-height: 13px;
                            min-width: 13px;
                            max-height: 13px;
                            max-width: 13px;

                            @media only screen and (max-width: 1750px) {
                              padding: 2px;
                            }
                          }
                        }
                      }

                      .dropdown-desc-sec {
                        .icon-part {
                          display: none;
                        }

                        .desc-part {
                          display: flex;
                          align-items: center;

                          .copy-icon {
                            img {
                              filter: unset;
                            }

                            &.primary-email {
                              position: relative;
                              display: flex;
                              align-items: center;
                              justify-content: center;

                              &::before {
                                content: "\f00c";
                                font-family: FontAwesome;
                                font-style: normal;
                                font-weight: normal;
                                text-decoration: inherit;
                                margin-left: 5px;
                                padding: 3px;
                                border-radius: 100%;
                                color: #fff;
                                background-color: var(--PrimaryColorBright);
                                font-size: 9px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                min-height: 13px;
                                min-width: 13px;
                                max-height: 13px;
                                max-width: 13px;

                                @media only screen and (max-width: 1750px) {
                                  padding: 2px;
                                 
                                }
                              }
                            }
                          }
                        }
                      }

                      .contact-list {
                        display: flex;
                        align-items: flex-start;

                        .contact-type-icon {
                          margin-right: 5px;
                          width: 15px;
                          display: flex;
                          align-items: center;
                          justify-content: center;

                          i {
                            font-size: var(--commonFont);
                          }
                        }

                        .number-list {
                          display: flex;
                          align-items: center;
                          margin-top: 2px;

                          &:last-child {
                            margin-top: 0;
                          }
                        }
                      }

                      .contact-sec {
                        .number-list {
                          display: flex;
                          align-items: center;

                          .number-type {
                            margin-right: 5px;
                            width: 15px;
                            height: 15px;
                            text-align: center;

                            span {
                              &.contact-type-icon {
                                .fa-mobile {
                                  font-size: 17px;
                                }
                              }
                            }
                          }
                        }
                      }

                      img {
                        width: 17px;
                        margin-left: 7px;
                        filter: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .customer-details {
        margin-top: 5px;

        padding-left: 5px;

        .email-address {
          .dropdown-desc-sec {
            display: flex;
            align-items: center;
            margin-bottom: 3px;
            width: 100%;

            .icon-part {
              margin-right: 10px;
              // display: none;
              color: var(--textColor2);

              i {
                font-size: var(--commonFont);
              }
            }

            .desc-part {
              display: flex;
              align-items: center;
              width: 100%;

              .values {
                color: var(--textColor2);
                font-size: 12.5px;
                text-wrap: nowrap;
                overflow: hidden;
                max-width: 140px;
                text-overflow: ellipsis;

                &.primary-email {
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  &::before {
                    content: "\f00c";
                    font-family: FontAwesome;
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: inherit;
                    margin-left: 5px;
                    padding: 3px;
                    border-radius: 100%;
                    color: #fff;
                    background-color: var(--PrimaryColorBright);
                    font-size: var(--commonFont-extraSmall);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 17px;

                    @media only screen and (max-width: 1750px) {
                      padding: 2px;
                      height: 15px;
                    }
                  }
                }

                @media only screen and (max-width: 1750px) {
                  font-size: 10.5px;
                }
              }

              .copy-icon {
                &.primary-email {
                  position: relative;
                  display: flex;
                  align-items: center;

                  &::before {
                    content: "\f00c";
                    font-family: FontAwesome;
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: inherit;
                    margin-left: 5px;
                    padding: 3px;
                    border-radius: 100%;
                    color: #fff;
                    background-color: var(--PrimaryColorBright);
                    font-size: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 13px;
                    min-width: 13px;
                    max-height: 13px;
                    max-width: 13px;

                    @media only screen and (max-width: 1750px) {
                      padding: 2px;
                    }
                  }
                }

                i {
                  margin-left: 5px;
                  color: var(--textColor);
                  font-size: var(--commonFont);
                  font-weight: bold;
                }
              }
            }
          }

          .drop-down {
            display: none;
          }
        }

        .contact-number {
          .contact-sec {
            .number-list {
              display: flex;
              align-items: center;
              width: 100%;

              .number-type {
                .contact-type-icon {
                  margin-right: 10px;
                  // display: none;
                  color: var(--textColor2);
                  width: 14px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  i {
                    font-size: var(--commonFont);

                    &.fa-mobile {
                      font-size: var(--commonFont-large);
                    }
                  }
                }
              }

              .copy-icon {
                cursor: pointer;

                i {
                  margin-left: 5px;
                  color: var(--textColor);
                  font-size: var(--commonFont);
                  font-weight: bold;
                }
              }

              .card-value {
                color: var(--textColor2);
                font-size: 11px;

                &.primary-phone {
                  position: relative;
                  display: flex;

                  &::after {
                    content: "\f00c";
                    font-family: FontAwesome;
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: inherit;
                    margin-left: 5px;
                    padding: 3px;
                    border-radius: 100%;
                    color: #fff;
                    background-color: var(--PrimaryColorBright);
                    font-size: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 13px;
                    min-width: 13px;
                    max-height: 13px;
                    max-width: 13px;

                    @media only screen and (max-width: 1750px) {
                      padding: 2px;
                      height: 15px;
                    }
                  }
                }

                @media only screen and (max-width: 1750px) {
                  font-size: var(--commonFont-extraSmall);
                }
              }
            }
          }

          .drop-down {
            display: none;
          }
        }

        .customer-type {
          margin-top: 5px;
          display: flex;
          width: fit-content;
          align-items: center;
          background: #e9e9ef;
          padding: 2px 10px;
          border-radius: 30px;
          position: absolute;
          bottom: 10px;

          .customer-type-icon {
            content: "";
            min-height: 10px;
            min-width: 10px;
            max-height: 10px;
            max-width: 10px;
            display: inline-block;
            background: var(--textColor);
            border-radius: 100%;
            margin-right: 5px;

            @media only screen and (max-width: 1750px) {
              min-height: 6px;
              min-width: 6px;
              max-height: 6px;
              max-width: 6px;
            }

            &.badge-default {
              background: var(--themeColorLight);
            }

            &.badge-primary {
              background: var(--PrimaryColor);
            }

            &.badge-endUser {
              background: var(--pending-theme-btn);
            }

            &.badge-purchasing {
              background: var(--submitted-theme-btn);
            }

            &.badge-submission {
              background: var(--submission-color-btn);
            }

            &.badge-followup {
              background: var(--frozen-theme-btn);
            }

            &.badge-ap {
              background: var(--disabled-theme-btn);
            }
          }

          .type-value {
            font-size: var(--commonFont-small);
            font-weight: 600;
            color: var(--textColor2);

            &.badge-default {
              color: var(--themeColorLight);
            }

            &.badge-primary {
              color: var(--PrimaryColor);
            }

            &.badge-endUser {
              color: var(--pending-theme-btn);
            }

            &.badge-purchasing {
              color: var(--submitted-theme-btn);
            }

            &.badge-submission {
              color: var(--submission-color-btn);
            }

            &.badge-followup {
              color: var(--frozen-theme-btn);
            }

            &.badge-ap {
              color: var(--disabled-theme-btn);
            }
          }
        }
      }
    }
  }
}

.custom-card-sec {
  .vertical-tab-inner {
    .tab-section {
      .tab-content {
        padding-bottom: 0;

        @media only screen and (max-width: 1750px) {
          height: calc(100vh - 315px);
        }
      }
    }
  }
}

// Contact Card New UI End


@media only screen and (max-width:1280px) {
  .custom-contact-card {
   
  
    .contact-main-card-section {
      width: 30%;
      min-width: 220px;
      
    }
  }
  .address-main-card-section{
    min-width: 32.33%;
    margin: 0 0.5%;
  }
}