// New Timeline Start
.organiazation-history {
    .main-card {
      max-height: calc(100vh - 200px);
      overflow: hidden;
      overflow-y: auto;
  
      @media only screen and (max-width: 1750px) {}
    }
  }
  
  .vertical-timeline {
    width: 100%;
    position: relative;
    padding: 0;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 115px;
      height: 100%;
      width: 4px;
      background: #e9ecef;
      border-radius: 0.25rem;
    }
  
    &.vertical-timeline--animate {
      .vertical-timeline-element-icon {
        &.bounce-in {
          visibility: visible;
          animation: cd-bounce-1 1s;
          -webkit-animation: cd-bounce-1 1s;
        }
      }
    }
  
    .vertical-timeline-element {
      position: relative;
      margin: 0 0 1rem;
  
      .vertical-timeline-element-icon {
        position: absolute;
        top: 0;
        left: 108px;
  
        .badge-dot-xl {
          width: 18px;
          height: 18px;
          position: relative;
          box-shadow: 0 0 0 5px #fff;
  
          &::before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 0.25rem;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -5px 0 0 -5px;
            background: var(--themeColor);
          }
        }
  
        .badge {
          background: #fff;
  
          &:empty {
            display: none;
          }
        }
      }
  
      .vertical-timeline-element-content {
        position: relative;
        margin-left: 135px;
        font-size: var(--commonFont-medium);
  
        &::after {
          content: "";
          display: table;
          clear: both;
        }
  
        .timeline-title {
          font-weight: normal;
          font-size: var(--commonFont);
          margin: 0;
          padding: 2px 0 0;
  
          .font-bold {
            font-weight: bold;
            color: var(--themeColor);
          }
        }
  
        .timeline-title.event-error {
          .font-bold {
            font-weight: bold;
            color: #dc3545;
          }
        }
  
        .timeline-title.event-success {
          .font-bold {
            font-weight: bold;
            color: #28a745;
          }
        }
  
        .vertical-timeline-element-date {
          display: block;
          position: absolute;
          left: -119px;
          top: 0;
          padding-right: 10px;
          text-align: left;
          color: #838990;
          font-size: var(--commonFont-medium);
          white-space: normal;
          width: 100px;
        }
      }
    }
  }
  
  // New Timeline End